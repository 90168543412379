<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <!-- Success -->
        <div *ngIf="showSuccessAlert" id="sucessPageAlert" class="ontario-alert ontario-alert--success">
            <div class="ontario-alert__header">
                <div class="ontario-alert__header-icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                        viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-success"></use>
                    </svg>
                </div>
                <h2 class="ontario-alert__header-title ontario-h4">{{'Profile.YourPlanHasBeenDeleted' | translate}}</h2>
            </div>
            <div class="ontario-alert__body">
                <p>{{'Profile.YourPlanHasBeenDeletedSummary' | translate}}</p>
            </div>
        </div>
        <div class="ontario-columns top-banner">
            <h1 class="title">
                {{'SafetyPlans.Title' | translate}}
                <span *ngIf="businessName"> - {{ businessName }}</span>
            </h1>
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-lead-statement" innerHtml="{{'SafetyPlans.Summary' | translate}}"></div><br />
            <button class="ontario-button ontario-button--primary" *ngIf="safetyPlans !== null && safetyPlans.length > 0"
                (click)="createNewPlan()">{{'SafetyPlans.CreatePlanButton' | translate}}
            </button>
        </div>
    </div>
    <div *ngIf="safetyPlans == null || safetyPlans.length <= 0">
        <div class="ontario-row">
            <div class="ontario-columns emptyState">
                <img class="ontario-card__image"  src="/assets/images/Empty_state_01.svg" alt="{{'SafetyPlans.NoSafetyPlanImageAlt' | translate}}">
                <div class="ontario-lead-statement">{{'SafetyPlans.NoSafetyPlanYet' | translate}}</div>
                <button class="ontario-button ontario-button--primary"
                    (click)="createNewPlan()">{{'SafetyPlans.CreatePlanButton' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="safetyPlans !== null && safetyPlans.length > 0">
        <div class="ontario-row">
            <div class="ontario-columns top-banner">
                <div class="tombstone" *ngFor="let plan of safetyPlans; let i = index">
                    <span *ngIf="plan.completed" class="safety-plan-tag-complete">
                        <strong class="ontario-badge ontario-badge--default-heavy">{{ 'Tags.Completed' | translate
                            }}</strong>
                    </span>
                    <span *ngIf="!plan.completed" class="safety-plan-tag-incomplete">
                        <strong class="ontario-badge ontario-badge--neutral-light">{{ 'Tags.Incomplete' | translate
                            }}</strong>
                    </span>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.PlanNamePlanHeading' | translate}}</div>
                        <div class="item-value">{{plan.planName}}</div>
                    </div>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.PlanCreatedOnHeading' | translate}}</div>
                        <div class="item-value">{{plan.createdOn | date: 'MMMM d, y' }}</div>
                    </div>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.PlanUpdatedOnHeading' | translate}}</div>
                        <div class="item-value">{{ plan.lastUpdatedOn | date: 'MMMM d, y' }} </div>
                    </div>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.PlanUpdatedByHeading' | translate}}</div>
                        <div class="item-value">{{ plan.lastUpdatedBy}} </div>
                    </div>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.ContactNameHeading' | translate}}</div>
                        <div class="item-value">{{plan.contactName}}</div>
                    </div>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.ContactEmailAddressHeading' | translate}}</div>
                        <div class="item-value">{{plan.contactEmail}}</div>
                    </div>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.ContactPhoneNumberHeading' | translate}}</div>
                        <div class="item-value">{{plan.contactNumber}}</div>
                    </div>
                    <div class="tombstone-item">
                        <div class="item-heading">{{'SafetyPlans.SafetyPlanNotesHeading' | translate}}</div>
                        <div class="item-value">{{plan.notes}}</div>
                    </div>
                    <div *ngIf="!plan.completed" class="tombstone-buttons">
                        <button (click)="continueSafetyPlan(plan.id)"
                            class="ontario-button ontario-button--secondary">{{'Buttons.Continue' |
                            translate}}</button>
                        <button class="ontario-button ontario-button--tertiary" mat-raised-button
                            (click)="deletePlan(plan.id)">{{ 'Profile.DeletePlan' | translate }}</button>
                    </div>
                    <div *ngIf="plan.completed" class="tombstone-buttons">
                        <button (click)="viewPlan(plan.id)"
                            class="ontario-button ontario-button--secondary">{{'SafetyPlans.ViewEditPlanButton' |
                            translate}}</button>
                        <button (click)="copyLink(plan.id, i)"
                            class="ontario-button ontario-button--secondary">{{'SafetyPlans.CopyLinkButton' |
                            translate}}</button><span style="padding-right: 20px; margin-top: 10px;"
                            *ngIf="i === copiedPlanIndex">{{'SafetyPlans.LinkCopied' | translate}}</span>
                        <button (click)="downloadSafetyPlan(plan)"
                            class="ontario-button ontario-button--secondary">{{'SafetyPlans.DownloadPDFButton' |
                            translate}}</button>
                        <button class="ontario-button ontario-button--tertiary" mat-raised-button
                            (click)="deletePlan(plan.id)">{{ 'Profile.DeletePlan' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>