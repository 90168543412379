import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FeedbackModel } from 'src/app/models/feedback.model';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private httpClient: HttpClient, private googleAnalyticsService: GoogleAnalyticsService) { }

  createFeedback(feedback: FeedbackModel): Observable<{success: boolean, message: string}> {
      this.googleAnalyticsService.eventEmitter("Phase2-feedback-submitted", "Test", "Test", "click", "test url");
      return this.httpClient.post<{success: boolean, message: string}>(environment.apiBaseUrl + `feedback`, feedback);
  }
}
