import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Step1Model } from 'src/app/models/models';
import { BusinessService } from '../business/business.service';
import { BusinessModuleService } from '../business-module/business-module.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  constructor(private httpClient: HttpClient, private businessService: BusinessService, private businessModuleService: BusinessModuleService) { }

  checkBusinessExists(): Observable<{message: string, value: string}> {
    return this.httpClient.get<{message: string, value: string}>(environment.apiBaseUrl + 'onboarding/BusinessExistsForUser');
  }

  getStep1Answers(): Observable<any> {
    return this.httpClient.get<any>(environment.apiBaseUrl + 'onboarding/Step1Answers');
  }

  getStep2Answers(): Observable<any> {
    return this.httpClient.get<any>(environment.apiBaseUrl + 'onboarding/Step2Answers');
  }

  updateBusinessStep1(data: any): Observable<boolean> {   
    this.businessService.invalidateCachedBusiness();
    return this.httpClient.post<boolean>(environment.apiBaseUrl + 'onboarding/UpdateBusinessStep1', data);
  }

  updateBusinessStep2(data: any): Observable<any> {
    this.businessService.invalidateCachedBusiness();
    return this.httpClient.post<any>(environment.apiBaseUrl + 'onboarding/UpdateBusinessStep2', data);
  }
  
  updateBusinessStep3(data: string[]): Observable<boolean> {    
    this.businessService.invalidateCachedBusiness();
    this.businessModuleService.invalidateCachedBusinessModules();
    return this.httpClient.post<boolean>(environment.apiBaseUrl + 'onboarding/UpdateBusinessStep3', data);
  }
}