<div class="ontario-form-group">
    <div class="closeButton">
        <label for="email-input">
            <h5>
                {{'WorkspaceManagement.EnterUserEmail' | translate}}
                <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </h5>
        </label>
        <button (click)="onCloseClick()" aria-label="Close">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-close"></use>
            </svg>
        </button>
    </div>
    
    <div class="ontario-error-messaging" *ngIf="emptyEmail" role="alert">
        <span class="ontario-error-messaging__icon">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-alert-error"></use>
            </svg>
        </span>
        <span class="ontario-error-messaging__content">
            {{'WorkspaceManagement.EmailBlankError' | translate}}
        </span>
    </div>
    <div class="ontario-error-messaging" *ngIf="tooLongEmail" role="alert">
        <span class="ontario-error-messaging__icon">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-alert-error"></use>
            </svg>
        </span>
        <span class="ontario-error-messaging__content">
            {{'WorkspaceManagement.EmailTooLongError' | translate}}
        </span>
    </div>
    <div class="ontario-error-messaging" *ngIf="xssCheckFailed" role="alert">
        <span class="ontario-error-messaging__icon">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-alert-error"></use>
            </svg>
        </span>
        <span class="ontario-error-messaging__content">
            {{'WorkspaceManagement.EmailSanitizeError' | translate}}
        </span>
    </div>
    <div class="ontario-error-messaging" *ngIf="invalidEmail" role="alert">
        <span class="ontario-error-messaging__icon">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-alert-error"></use>
            </svg>
        </span>
        <span class="ontario-error-messaging__content">
            {{'WorkspaceManagement.EmailInvalidError' | translate}}
        </span>
    </div>
    <div class="ontario-error-messaging" *ngIf="userExist" role="alert">
        <span class="ontario-error-messaging__icon">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-alert-error"></use>
            </svg>
        </span>
        <span class="ontario-error-messaging__content">
            {{'WorkspaceManagement.UserExistError' | translate}}
        </span>
    </div>
    <div class="ontario-error-messaging" *ngIf="isUserInOtherBusiness" role="alert">
        <span class="ontario-error-messaging__icon">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-alert-error"></use>
            </svg>
        </span>
        <span class="ontario-error-messaging__content">
            {{'WorkspaceManagement.UserHaveOwnBusiness' | translate}}
        </span>
    </div>
    <input type="text" class="ontario-input" id="email-input" [class.ontario-input__error]="emptyEmail || tooLongEmail || xssCheckFailed || invalidEmail || userExist || isUserInOtherBusiness" [(ngModel)]="email" cdkFocusInitial/>
    <button (click)="submitClick()" class="ontario-button ontario-button--primary">
        {{'Buttons.Add' | translate}}
    </button>
    <button (click)="onCloseClick()" class="ontario-button ontario-button--secondary">
        {{'WorkspaceManagement.Cancel' | translate}}
    </button>
</div>

