<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{'AdditionalSupport.Heading' | translate}}
          </h1>          
          <div class="ontario-lead-statement" innerHtml="{{'AdditionalSupport.Summary' | translate}}"></div><br />
          <div  class="link"><p innerHtml="{{'AdditionalSupport.Link1' | translate}}"></p> </div>
          
          <ng-container *ngIf="this.step1.BusinessSector === 'Construction'">
            <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.Construction1' | translate}}"></p> </div>
          </ng-container>

          <ng-container *ngIf="this.step1.BusinessSector === 'Construction'">
            <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.Construction2' | translate}}"></p> </div>
          </ng-container>

          <ng-container *ngIf="this.hasDivingActivity || this.hasWindowCleaningActivity">
            <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.WindowCleaning' | translate}}"></p> </div>
          </ng-container>

          <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.Link5' | translate}}"></p> </div>
          <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.Link6' | translate}}"></p> </div>

          <ng-container *ngIf="this.step1.BusinessSector === 'Construction'">
            <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.Construction3' | translate}}"></p> </div>
          </ng-container>

          <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.Link7' | translate}}"></p> </div>
          
          <ng-container *ngIf="this.step1.BusinessSector === 'MiningQuarryingOilAndGasExtraction'">
            <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.MiningQuarryingOilAndGasExtraction' | translate}}"></p> </div>
          </ng-container>

          <ng-container *ngIf="this.step1.BusinessSector === 'HospitalsHealthcareAndRelatedSocialServices'">
            <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.HospitalsHealthcareAndRelatedSocialServices' | translate}}"></p> </div>
          </ng-container>

          <ng-container *ngIf="this.step1.HasDesignatedSubstances === 'Yes'">
            <br /><div  class="link"><p innerHtml="{{'AdditionalSupport.DSR' | translate}}"></p> </div>
          </ng-container>
        </div>
    </div>
    
    
</div>
   