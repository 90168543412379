import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetService } from 'src/app/services/reset/reset.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-reset',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent {
  sectionBusinessModuleId: string = "";
  answerBusinessModuleId: string = "";
  hazardModuleId: string = "";
  hazardBusinessId: string = "";
  businessId: string = "";
  
  constructor(private resetService: ResetService){
  }

  deleteAnswers() {
    this.resetService.deleteAnswers(this.answerBusinessModuleId).subscribe(_ => {
      console.log("answer delete for the businessModuleId: " + this.answerBusinessModuleId);
    });
  }

  deleteHazardsCurrentBusiness() {
    this.resetService.deleteHazardsCurrentBusiness().subscribe(_ => {
      console.log("Hazards deleted for current user");
    });
  }

  deleteHazard() {
    this.resetService.deleteHazard(this.hazardModuleId).subscribe(_ => {
      console.log("Hazard deleted with the module Id: " + this.hazardModuleId);
    });
  }

  deleteHazards() {
    this.resetService.deleteHazards(this.hazardBusinessId).subscribe(_ => {
      console.log("Hazards deleted for the business Id: " + this.hazardBusinessId);
    });
  }

  deleteSectionsStatus() {
    this.resetService.deleteSectionsStatus(this.answerBusinessModuleId).subscribe(_ => {
      console.log("Section status removed for the module with the businessModuleId: " + this.answerBusinessModuleId);
    });
  }

  deleteSafetyPlansCurrentBusiness() {
    this.resetService.deleteSafetyPlansCurrentBusiness().subscribe(_ => {
      console.log("All safety plans deleted for current business");
    });
  }

  deleteCurrentBusiness() {
    this.resetService.deleteCurrentBusiness().subscribe(_ => {
      console.log("Current business deleted");
    });
  }

  deleteBusiness() {
    this.resetService.deleteBusiness(this.businessId).subscribe(_ => {
      console.log("Hazards deleted for the business Id: " + this.businessId);
    });
  }

}
