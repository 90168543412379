<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row ontario-row-hero" *ngIf="this.completed == 'y'">
        <div class="ontario-alert ontario-alert--success">
          <div class="ontario-alert__header">
            <div class="ontario-alert__header-icon">
              <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="#ontario-icon-alert-success"></use>
              </svg>
            </div>
            <h2 class="ontario-alert__header-title ontario-h4">{{'Hazards.AlertHeading' | translate}}</h2>
          </div>
          <div class="ontario-alert__body">
            <p innerHtml="{{'Hazards.AlertSummary' | translate}}"></p>
          </div>
        </div>
      </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h1 class="title">
                {{'Hazards.Title' | translate}}
            </h1>
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-lead-statement" innerHtml="{{'Hazards.Summary' | translate}}"></div><br />
            <div class="ontario-hint-expander__container">
                <button class="ontario-hint-expander__button" id="hint-expander-button-id-1"
                    aria-controls="hint-expander-content-1" aria-expanded="false"
                    data-toggle="ontario-collapse">
                    <span class="ontario-hint-expander__button-icon--close"><svg class="ontario-icon" alt=""
                            aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24"
                            preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-chevron-up"></use>
                        </svg></span>
                    <span class="ontario-hint-expander__button-icon--open"><svg class="ontario-icon" alt=""
                            aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24"
                            preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-chevron-down"></use>
                        </svg></span>
                    {{ 'Hazards.RACEcalloutHeading' | translate}}
                </button>
                <div class="ontario-hint-expander__content" id="hint-expander-content-1"
                    aria-labelledby="hint-expander-button-id-1" aria-hidden="true"
                    data-toggle="ontario-expander-content">
                    <p innerHTML="{{ 'Hazards.RACEcallout' | translate}}"></p>
                </div>
            </div>
            <br />
        </div>
        
    </div>
    <div *ngIf="conditionalCallout" class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-callout">
                <h2 class="ontario-callout__title ontario-h5">{{'Hazards.CalloutHeading' | translate}}</h2>
                <p>
                    {{'Hazards.CalloutTextStart' | translate}} 
                    <a routerLink="../safetyplans" routerLinkActive='active'>{{'Hazards.CalloutLink' | translate}}</a> 
                    {{'Hazards.CalloutTextEnd' | translate}}
                </p>
            </div>           
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns">
            <div class="ontario-columns top-banner ontario-padding-top-16-!">
                <h2>{{'Hazards.HazardTitle' | translate}}</h2>
                <ul class="ontario-card__container ontario-card--cards-per-row-3">
                    <li *ngFor="let businessModule of filterModules('Hazard', false)"
                        class="ontario-card ontario-card--light  ontario-card--position-vertical"
                        (click)="displayDetail(businessModule.module.id)">
                        <div class="ontario-card__image-container">
                            <img class="ontario-card__image" src="{{businessModule.module.imageURL}}"
                                alt="">
                        </div>
                        <div class="ontario-card__text-container ontario-card--image-true">
                            <h2 class="ontario-card__heading">
                                <a href="javascript:{}">{{translate.currentLang === 'en' ? businessModule.module.name :
                                businessModule.module.nameFR}}</a>
                            </h2>
                            <div class="ontario-card__description">
                                <!-- <p innerHtml="{{translate.currentLang === 'en' ? (businessModule.module.summary.length > 190 ? (businessModule.module.summary | slice:0:190) + '...' : businessModule.module.summary) : (businessModule.module.summaryFR.length > 190 ? (businessModule.module.summaryFR | slice:0:190) + '...' : businessModule.module.summaryFR)}}"></p> -->
                                <span *ngIf="businessModule.completedSectionsCount > 0">
                                    <strong>{{'Hazards.HazardUpdatedOn' | translate}} </strong>
                                    {{businessModule.lastUpdatedOn | date:"MMM dd, YYYY"}} {{'Hazards.HazardUpdatedBy' |
                                    translate}} {{businessModule.lastUpdatedBy}}
                                </span>
                                <p *ngIf="businessModule.completed"><strong
                                        class="ontario-badge ontario-badge--default-heavy">{{'Tags.Completed' |
                                        translate}}</strong></p>
                                <p *ngIf="!businessModule.completed && businessModule.completedSectionsCount > 0">
                                    <strong class="ontario-badge ontario-badge--neutral-light">{{'Tags.Incomplete' |
                                        translate}}</strong></p>
                                <p *ngIf="!businessModule.completed && businessModule.completedSectionsCount == 0">
                                    <strong class="ontario-badge ontario-badge--default-light">{{'Tags.NotStarted' |
                                        translate}}</strong></p>
                            </div>
                        </div>
                    </li>
                    <li class="ontario-card ontario-card--light  ontario-card--position-vertical"
                        (click)="router.navigate(['customHazard'], { relativeTo: this.activatedRoute})">
                        <div class="ontario-card__image-container">
                            <img class="ontario-card__image" src="/assets/images/hazard-custom.svg"
                                alt="">
                        </div>
                        <div class="ontario-card__text-container ontario-card--image-true">
                            <h2 class="ontario-card__heading">
                               <a href="javascript:{}">{{'CustomHazard.AddToList' | translate}} </a>
                            </h2>

                            <div class="ontario-card__description">
                                <p>{{'CustomHazard.Title' | translate}}</p>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
            <div class="ontario-columns top-banner ontario-padding-top-16-!">
                <h2>{{'Hazards.SubheadingDismissed' | translate}}</h2>
                <p>{{'Hazards.SubheadingDismissedSummary' | translate}}</p><br />
                <p *ngIf="!dismissedHazards">{{'Hazards.NoDismissedModules' | translate }}</p>
                <ul class="ontario-card__container ontario-card--cards-per-row-3">
                    <li *ngFor="let businessModule of filterModules('Hazard', true)"
                        class="ontario-card ontario-card--light  ontario-card--position-vertical"
                        (click)="displayDetail(businessModule.module.id)">
                        <div class="ontario-card__image-container">
                            <img class="ontario-card__image" src="{{businessModule.module.imageURL}}"
                                alt="{{businessModule.module.name}}">
                        </div>
                        <div class="ontario-card__text-container ontario-card--image-true">
                            <h2 class="ontario-card__heading">
                                {{translate.currentLang === 'en' ? businessModule.module.name :
                                businessModule.module.nameFR}}
                            </h2>

                            <div class="ontario-card__description">
                                <!-- <p innerHtml="{{translate.currentLang === 'en' ? (businessModule.module.summary.length > 190 ? (businessModule.module.summary | slice:0:190) + '...' : businessModule.module.summary) : (businessModule.module.summaryFR.length > 190 ? (businessModule.module.summaryFR | slice:0:190) + '...' : businessModule.module.summaryFR)}}"></p> -->
                                <strong>{{'Hazards.HazardUpdatedOn' | translate}} </strong>
                                {{businessModule.lastUpdatedOn | date:"MMM dd, YYYY"}} {{'Hazards.HazardUpdatedBy' |
                                translate}} {{businessModule.lastUpdatedBy}}
                                <p *ngIf="businessModule.dismissed"><strong
                                        class="ontario-badge ontario-badge--neutral-heavy">{{'Tags.Dismissed' |
                                        translate}}</strong></p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="ontario-row">

    </div>