import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Step2Model } from 'src/app/models/step2.model';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

declare function scrollToError(): void

@Component({
  selector: 'app-step2',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component {
  selectedOptions: string[] = [];

  step2: Step2Model = {
    MultipleLocations: null,
    HeadOfficeLocation: '',
    Regions: ''
  } as Step2Model;

  constructor(private translate: TranslateService, 
    private router: Router, 
    private onboardingService: OnboardingService,
    private spinnerService: SpinnerService) { }

  CentralEast: boolean = false;
  CentralWest: boolean = false;
  East: boolean = false;
  North: boolean = false;
  West: boolean = false;
  
  toggleExpander(expandableItem: any) {
    let item = expandableItem.currentTarget;
    item.parentNode.classList.toggle("ontario-expander--active");
    var content = item.parentNode.querySelector(
      "[data-toggle='ontario-expander-content']"
    );
    content.classList.toggle("ontario-expander__content--opened");
    content.classList.contains("ontario-expander__content--opened")
      ? content.setAttribute("aria-hidden", "false")
      : content.setAttribute("aria-hidden", "true");
    item.parentNode.classList.contains("ontario-expander--active")
      ? item.setAttribute("aria-expanded", "true")
      : item.setAttribute("aria-expanded", "false");
  }

  ngOnInit() {
    this.loadUserInitialAnswers();
  }

  loadUserInitialAnswers() {
    this.onboardingService.getStep2Answers().subscribe(
      (initialAnswers) => {
        if (initialAnswers != null) {
          if (initialAnswers.step2Completed === true) {
            if (initialAnswers.regions != null && initialAnswers.regions != "") {
              let businessLocations: string[] = initialAnswers.regions.split(",");
              businessLocations.forEach(location => {
                Object.assign(this, {[location]: true});
                this.onCheckboxChange(location);
              });
            }
            this.step2.MultipleLocations = initialAnswers.multipleLocations;
            this.step2.HeadOfficeLocation = initialAnswers.headOfficeLocation || '';
            this.step2.Step2Completed = initialAnswers.step2Completed;
            this.step2.OnboardingCompleted = initialAnswers.onboardingCompleted;
          }
        }
      });
  }

  onCheckboxChange(optionValue: string) {
    if (this.selectedOptions.includes(optionValue)) {
      this.selectedOptions = this.selectedOptions.filter(val => val !== optionValue);
    } else {
      this.selectedOptions.push(optionValue);
    }
  }

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  saveAndContinue() {
    this.step2.Regions = this.selectedOptions.toString();
    this.onboardingService.updateBusinessStep2(this.step2).subscribe({
      next: () => {
        if (this.step2.Step2Completed && this.step2.OnboardingCompleted) {
          this.router.navigate([this.translate.currentLang == 'en' ? 'profile' : 'fr/profile'])
        } else {
          this.router.navigate([this.translate.currentLang == 'en' ? 'step3' : 'fr/step3'])
        }
      },
      error: (error) => {
        this.spinnerService.hide();
      }
    });
  }

  back() {
    this.router.navigate([this.translate.currentLang == 'en' ? 'step1' : 'fr/step1'])
  }
}
