import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jhsc',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './jhsc.component.html',
  styleUrls: ['./jhsc.component.scss']
})
export class JHSCComponent {
  constructor(public authService: AuthService, public translate: TranslateService, public router: Router) { }
}
