<div class="ontario-padding-bottom-48-!">
  <div class="ontario-row">
      <div class="ontario-columns top-banner">
        <!-- Error -->
        <div id="errorDiv" #errorDiv>
          <div *ngIf="validationFailed" class="ontario-alert ontario-alert--error">
              <div class="ontario-alert__header">
                  <div class="ontario-alert__header-icon">
                      <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                          <use href="#ontario-icon-alert-error"></use>
                      </svg>
                  </div>
                  <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
              </div>
              <div class="ontario-alert__body">
                  <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                  <ul>
                      <li *ngIf="nameTooLong"><a href="javascript:{}" (click)="scrollToElement('businessNamelabel')">{{'Healthandsafetypledge.BusinessNameMaxLength' | translate }}</a></li>
                      <li *ngIf="nameInvalid"><a href="javascript:{}" (click)="scrollToElement('businessNamelabel')">{{ 'ViolenceAndHarassment.BusinessNameHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                  </ul>
              </div>
          </div>
        </div>
        <h1 class="title">
          {{'ViolenceAndHarassment.Title' | translate}}
        </h1>
        <p class="ontario-lead-statement" innerHtml="{{ 'ViolenceAndHarassment.Summary' | translate }}"></p>
        <br>          
      </div>
  </div>
  
  <div class="ontario-row">
    <div class="ontario-columns">
      <div class="ontario-form-group">
            <label class="ontario-label" for="businessName" id="businessNamelabel">
                {{'ViolenceAndHarassment.BusinessNameHeading' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
            </label>
            <div *ngIf="nameTooLong || nameInvalid" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span *ngIf="nameTooLong" class="ontario-error-messaging__content">
                    {{ 'Healthandsafetypledge.OwnerNameTooLongError' | translate }}.
                </span>
                <span *ngIf="nameInvalid" class="ontario-error-messaging__content">
                    {{'ViolenceAndHarassment.EmployerNameHeading' | translate}} {{'HardcodedGlobals.InvalidError' | translate}}
                </span>
            </div>
            <p class="ontario-hint">{{'ViolenceAndHarassment.InputFieldHint' | translate}}</p><br><br>
            <input class="ontario-input" type="text" id="businessName" [(ngModel)]="businessName" [class.ontario-input__error]="nameTooLong || nameInvalid">
      </div>
    </div>
  </div>

  <div class="ontario-row">
    <div class="ontario-callout"> 
      <h2 class="ontario-callout__title ontario-h2">{{ 'ViolenceAndHarassment.CalloutHeadingHarassment' | translate }}</h2>
      <div class="link">
        <div *ngIf = "EnPage">
          <p>
            <b>{{businessName.length ? businessName : '[Insert name of business]'}}</b> is committed to providing a work environment in which all workers are treated with respect and dignity. Workplace harassment will not be tolerated from any person in the workplace including customers, clients, other employers, supervisors, workers and members of the public, as applicable.<br><br>
            Workplace harassment means engaging in a course of vexatious comment or conduct against a worker in a workplace that is known or ought reasonably to be known to be unwelcome or workplace sexual harassment.<br><br>
            Workplace sexual harassment means:<br><br>
          </p>
          <ol>
            <li>
              engaging in a course of vexatious comment or conduct against a worker in a workplace because of sex, sexual orientation, gender identity or gender expression, where the course of comment or conduct is known or ought reasonably to be known to be unwelcome, or
            </li>
            <li>
              making a sexual solicitation or advance where the person making the solicitation or advance is in a position to confer, grant or deny a benefit or advancement to the worker and the person knows or ought reasonably to know that the solicitation or advance is unwelcome;
            </li>
          </ol>
          <p>
            Reasonable action taken by the employer or supervisor relating to the management and direction of workers or the workplace is not workplace harassment.<br><br>
            Workers are encouraged to report any incidents of workplace harassment to the appropriate person. Employer may specify the person or department to report any incident(s) of workplace harassment.
          </p>
          <br />
          <table role="presentation">
            <tr style="display: table-header-group;">
              <td style="padding: 0%;"><hr style="border-color: #1A1A1A;"></td>
              <td style="padding: 0%;"></td>
            </tr>
            <tr>
              <th>[Enter name/ department]</th>
            </tr>
          </table> 
          <br />
          <p>
            Management will investigate and deal with all complaints or incidents of workplace harassment in a fair, respectful and timely manner. Information provided about an incident or about a complaint will not be disclosed except as necessary to protect workers, to investigate the complaint or incident, to take corrective action or as otherwise required by law.<br><br>
            Managers, supervisors and workers are expected to adhere to this policy, and will be held responsible by the employer for not following it. Workers are not to be penalized or disciplined for reporting an incident or for participating in an investigation involving workplace harassment.<br><br>
            If a worker needs further assistance, he or she may contact: 
          </p>
          <br />
          <table role="presentation">
            <tr style="display: table-header-group;">
              <td style="padding: 0%;"><hr style="border-color: #1A1A1A;"></td>
            </tr>
            <tr>
              <th>[insert union (if any), JHSC or health and safety representative (if any), Human Rights Legal Support Centre or employee assistance program if available]</th>  
            </tr>
          </table>
        </div>
        <div *ngIf = "FrPage">
          <p>
            <b>{{businessName.length ? businessName : '[Insérer le nom de l’entreprise]'}}</b> s’engage à offrir un environnement de travail où tous les travailleurs sont traités avec respect et dignité. Aucun harcèlement ne sera toléré de la part de qui que ce soit dans le lieu de travail clients, employeurs, superviseurs, travailleurs et membres du grand public, selon le cas.<br><br>
            Le harcèlement au travail s’entend du fait pour une personne d’adopter une ligne de conduite caractérisée par des remarques ou des gestes vexatoires contre un travailleur dans un lieu de travail lorsqu’elle sait ou devrait raisonnablement savoir que ces remarques ou ces gestes sont importuns ou du harcèlement sexuel au travail. Le harcèlement sexuel au travail, quant à lui, s’entend:<br><br>
          </p>
          <ol type="a">
            <li>
              du fait pour une personne d’adopter, pour des raisons fondées sur le sexe, l’orientation sexuelle, l’identité sexuelle ou l’expression de l’identité sexuelle, une ligne de conduite caractérisée par des remarques ou des gestes vexatoires contre un travailleur dans un lieu de travail lorsqu’elle sait ou devrait raisonnablement savoir que ces remarques ou ces gestes sont importuns;
            </li>
            <li>
              du fait pour une personne de faire des sollicitations ou des avances sexuelles alors qu’elle est en mesure d’accorder au travailleur ou de lui refuser un avantage ou une promotion et qu’elle sait ou devrait raisonnablement savoir que ces sollicitations ou ces avances sont importunes.
            </li>
          </ol>
          <p>
            Les mesures raisonnables prises par l’employeur ou le superviseur dans le cadre de la gestion et de la direction des travailleurs ou du lieu de travail ne constituent pas du harcèlement au travail.<br><br>
            Les travailleurs sont encouragés à signaler tout incident de harcèlement au travail à la personne désignée. L’employeur peut préciser à quelle personne ou à quel service signaler les incidents de harcèlement au travail.
          </p>
          <br />
          <table role="presentation">
            <tr style="display: table-header-group;">
              <td style="padding: 0%;"><hr style="border-color: #1A1A1A;"></td>
              <td style="padding: 0%;"></td>
            </tr>
            <tr>
              <th>[Entrez le nom/le département]</th>
            </tr>
          </table>
          <br />
          <p>
            La direction enquêtera rapidement, équitablement et respectueusement sur toute plainte ou tout incident de harcèlement au travail et prendra les mesures qui s’imposent. Les renseignements obtenus au sujet d’un incident ou d’une plainte demeureront confidentiels, sauf si leur divulgation est nécessaire pour protéger les travailleurs, enquêter sur la plainte ou l’incident ou prendre des mesures correctives, ou encore si elle est exigée par la loi.<br><br>
            Les gestionnaires, les superviseurs et les travailleurs sont tenus d’adhérer à la présente politique et devront rendre des comptes à l’employeur pour tout manquement. Les travailleurs ne doivent pas être punis ni faire l’objet de mesures disciplinaires lorsqu’ils signalent un incident de harcèlement au travail ou participent à une enquête sur un tel incident.<br><br>
            Si un travailleur a besoin d’une aide supplémentaire, il peut consulter: 
          </p>
          <br />
          <table role="presentation">
            <tr style="display: table-header-group;">
              <td style="padding: 0%;"><hr style="border-color: #1A1A1A;"></td>
              <td style="padding: 0%;"></td>
            </tr>
            <tr>
              <th>[préciser: syndicat, comité mixte sur la santé et la sécurité au travail ou délégué à la santé et à la sécurité (selon le cas), Centre d’assistance juridique en matière de droits de la personne ou Programme d’aide aux employés (si un tel programme existe)]</th>
            </tr>
          </table>
        </div>
        <br />
        <table role="presentation">
          <tr style="display: table-header-group;">
            <td style="padding: 0%;"><hr style="margin-right: 10px; border-color: #1A1A1A;"></td>
            <td style="padding: 0%;"><hr style="border-color: #1A1A1A;"></td>
          </tr>
          <tr>
            <th>{{ 'ViolenceAndHarassment.SignedOrApproved' | translate }}: </th> 
            <th>{{ 'ViolenceAndHarassment.Date' | translate }}</th>
          </tr>
        </table>
      </div>
    </div>

    <div class="ontario-callout"> 
      <h2 class="ontario-callout__title ontario-h2">{{ 'ViolenceAndHarassment.CalloutHeadingViolence' | translate }}</h2>
      <div class="link">
        <div *ngIf = "EnPage">
          <p>
            The management of <b>{{businessName.length ? businessName : '[insert name of business]'}}</b> is committed to the prevention of workplace violence and is responsible for worker health and safety. We will take whatever steps are reasonable to protect our workers from workplace violence from all sources.<br /><br />
            Violent behavior in the workplace is unacceptable to anyone. Everyone is expected to uphold this policy and to work together to prevent workplace violence.<br /><br />
            There is a workplace violence program that implements this policy. It includes measures and procedures to protect workers from workplace violence, a means of summoning immediate assistance and a process for workers to report incidents or raise concerns.<br /><br />
            <b>{{businessName.length ? businessName : '[Insert name of business]'}}</b>, as the employer, will ensure this policy and the supporting program are implemented and maintained. All workers and supervisors will receive appropriate information and instruction on the policy and program's contents.<br /><br />
            Supervisors will adhere to this policy and the supporting program. Supervisors are responsible for ensuring that measures and procedures are followed by workers and that workers have the information they need to protect themselves.<br /><br />
            Every worker must work in compliance with this policy and the supporting program. All workers are encouraged to raise any concerns about workplace violence and to report any violent incidents or threats.<br /><br />
            Management pledges to investigate and deal with all incidents and complaints of workplace violence in a fair and timely manner, respecting the privacy of all concerned as much as possible.
          </p>
        </div>            
        <div *ngIf = "FrPage">
          <p>
            La direction de <b>{{businessName.length ? businessName : '[insérer le nom de l’entreprise]'}}</b> s’engage à prévenir la violence au travail et est responsable, en dernier ressort, de la santé et de la sécurité des travailleuses et travailleurs. Elle prendra toutes les mesures raisonnables pour les protéger contre la violence au travail, quelle qu’en soit la source.<br /><br />
            Il est inacceptable pour quiconque d’avoir des comportements violents au travail. Tout le monde doit respecter la présente politique et collaborer afin de prévenir la violence au travail.<br /><br />
            Il existe un programme concernant la violence au travail qui sert à mettre en œuvre la politique. Ce programme comprend des mesures et des méthodes permettant de protéger les travailleuses et travailleurs, d’obtenir une aide immédiate et de signaler les incidents ou de soulever des motifs de préoccupation.<br /><br />
            En tant qu’employeur, <b>{{businessName.length ? businessName : '[insérer le nom de l’entreprise]'}}</b> veillera à ce que la présente politique et le programme connexe soient mis en œuvre et maintenus, et à ce que tous les travailleurs et travailleuses et les superviseurs et superviseures disposent des renseignements et directives nécessaires à l’application de la politique et à l’exécution du programme.<br /><br />
            Toute personne qui supervise doit se conformer à la présente politique et au programme connexe. Il lui incombe de voir à ce que les travailleurs et travailleuses appliquent les mesures et méthodes établies et disposent des renseignements dont ils et elles ont besoin pour se protéger.<br /><br />
            Chaque travailleur et travailleuse doit respecter la présente politique et le programme connexe. Tous sont encouragés à soulever leurs préoccupations au sujet de la violence au travail et à signaler les incidents ou les menaces de violence.<br /><br />
            La direction s’engage à enquêter sur tous les incidents et les plaintes de violence au travail et à intervenir d’une manière équitable et rapide en respectant autant que possible la vie privée de toutes les personnes concernées.
          </p>
        </div>
        <table role="presentation">
          <tr style="display: table-header-group;">
            <td style="padding: 0%;"><hr style="margin-right: 10px; border-color: #1A1A1A;"></td>
            <td style="padding: 0%;"><hr style="border-color: #1A1A1A;"></td>
          </tr>
          <tr>
            <th>{{ 'ViolenceAndHarassment.Signed' | translate }}: </th> 
            <th>{{ 'ViolenceAndHarassment.Date' | translate }}</th>
          </tr>
        </table>
      </div>
    </div>
  
    <button (click)="handlePDFActions()" class="ontario-button ontario-button--primary">
        {{ 'ViolenceAndHarassment.DownloadPolicy' | translate}}
    </button>
    <button (click)="downloadWord()" class="ontario-button ontario-button--primary">
      {{ 'ViolenceAndHarassment.DownloadWord' | translate}}
  </button>
    <button (click)="saveAndClose()" class="ontario-button ontario-button--secondary">
        {{ 'ViolenceAndHarassment.SaveInformation' | translate}}
    </button>
  </div>
</div>