import {Injectable} from '@angular/core';

declare const logAnalytics: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(){}

  public eventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string, eventSource: string) {
    logAnalytics('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventSource:eventSource
    })
  }
}

