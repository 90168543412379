import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-email-preferences-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FormsModule, TranslateModule],
  templateUrl: './email-preferences-dialog.component.html',
  styleUrls: ['./email-preferences-dialog.component.scss']
})
export class EmailPreferencesDialogComponent {
  emailPreferences = inject(MAT_DIALOG_DATA);

  constructor(public dialogRef: MatDialogRef<EmailPreferencesDialogComponent>, public dialog: MatDialog, public translate: TranslateService){}

  onCloseClick(): void {
    this.dialogRef.close();
  }
  
  submitClick() {
      this.dialogRef.close(this.emailPreferences);
  }
}
