<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{'MentalHealth.Heading' | translate}}
          </h1>          
          <p class="ontario-lead-statement">{{'MentalHealth.Summary' | translate}}</p>
          <br />
          <div  class="link"><p innerHtml="{{'MentalHealth.Link1' | translate}}"></p> </div>
          <br /><div  class="link"><p innerHtml="{{'MentalHealth.Link2' | translate}}"></p> </div>
        </div>
    </div>
    
</div>
   