import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { BusinessModuleModel, ControlMeasureModel } from 'src/app/models/models';
import { AnswerService } from 'src/app/services/answer/answer.service';
import { ControlMeasureService } from 'src/app/services/control-measure/control-measure.service';

@Component({
  selector: 'app-hazardsummary',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './hazardsummary.component.html',
  styleUrls: ['./hazardsummary.component.scss']
})
export class HazardsummaryComponent {
  businessModule: BusinessModuleModel = {
    id: '',
    completed: false,
    dismissed: false,
    lastUpdatedBy: '',
    lastUpdatedOn: '',
    answers: [],
    module: {
      id: '',
      name: '',
      nameFR: '',
      summary: '',
      summaryFR: '',
      type: '',
      priority: 0,
      imageURL: '',
      dismissible: true
    },
    completedSectionsCount: 0
  };
  hazardId: string = '';
  hazardAnswersS1: {answerText: string, questionId: number}[] = [];
  hazardAnswersS2A1: ControlMeasureModel[] = [];
  hazardAnswersS2A2: {answerText: string, questionId: number}[] = [];

  constructor(public translate: TranslateService, private activatedRoute: ActivatedRoute, public businessModuleService: BusinessModuleService, private router: Router, private answerService: AnswerService, private controlMeasureService: ControlMeasureService) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hazardId = params['id'];
      this.businessModuleService.getModule(this.hazardId).subscribe(businessModule => {
        this.businessModule = businessModule;
        for (const section of businessModule.sections!) {
          this.answerService.getAnswers(section.id, businessModule.id).subscribe(data => {
            if (section.priority == "2") {
              this.controlMeasureService.getControlMeasure(this.businessModule.module.id).subscribe(cm => {
                this.controlMeasureService.getSelectedControlMeasures(this.businessModule.id).subscribe(bmCm => {
                  this.hazardAnswersS2A1 = [];
                  for (const b of bmCm) {
                    let c = cm.find(x => x.id == b.controlMeasureID)
                    if (c)
                      this.hazardAnswersS2A1.push(c);
                  }
                  this.hazardAnswersS2A1 = this.hazardAnswersS2A1.sort((a, b) => a.priority - b.priority);
                });
              });
            }
            if (data.length != 0) {
              if (section.priority == "1") 
                this.hazardAnswersS1 = data;
              else {
                this.hazardAnswersS2A2 = this.filterAnswers(data, 2);
              }
            }
          });
        }
      });
    });
  }

  filterAnswers(answers: {answerText: string, questionId: number}[], questionNum: number) {
    return answers.filter(a => a.questionId == questionNum);
  }

  backToSection(sectionId: number) {
    this.router.navigate(['../../', sectionId, this.hazardId], { relativeTo: this.activatedRoute})
  }

  saveAndComplete() {
    this.router.navigate(['../../../hazards'], { queryParams: {c: 'y'}, relativeTo: this.activatedRoute })
  }

}
