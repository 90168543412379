import { CanActivateFn, Router } from '@angular/router';
import { UserInfoService } from '../services/user-info/user-info.service';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export const resetGuard: CanActivateFn = (route, state) => {
  const userInfoService = inject(UserInfoService);
  const router = inject(Router);

  return userInfoService.getUserInfo().pipe(
    map(data => {
      const userData = JSON.parse(data.value);
      if (userData.Email === "ivo.kirov@gmail.com") {
        return true;
      }
      return router.createUrlTree(['/']);
    }),
    catchError(() => {
      return of(router.createUrlTree(['/']));
    })
  );
};
