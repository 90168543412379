<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h1 class="title">
                {{'Safetyboard.Safetyboard' | translate}}
            </h1>
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-lead-statement">{{'Safetyboard.Summary' | translate}}</div><br />
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <ul class="ontario-card__container ontario-card--cards-per-row-3">
                <li class="ontario-card ontario-card--light  ontario-card--position-vertical  ">
                    <div class="ontario-card__image-container">
                        <img class="ontario-card__image" src="/assets/images/HSP-logo.png" style="height: 174px;"
                            alt="{{'Safetyboard.PledgeAlt' | translate}}">
                    </div>
                    <div class="ontario-card__text-container ontario-card--image-true">
                        <h2 class="ontario-card__heading">
                            <a [routerLink]="['healthandsafetypledge']">{{'Safetyboard.Pledge' | translate}}</a>
                        </h2>

                        <div class="ontario-card__description">
                            <p innerHTML="{{'Safetyboard.PledgeSummary' | translate}}"></p>
                        </div>
                    </div>
                </li>
                <li class="ontario-card ontario-card--light  ontario-card--position-vertical  ">
                    <div class="ontario-card__image-container">
                        <img class="ontario-card__image" src="/assets/images/Violence-and-Harassment-Policy.png"
                            style="height: 174px;"
                            alt="{{'Safetyboard.ViolenceAlt' | translate}}">
                    </div>
                    <div class="ontario-card__text-container ontario-card--image-true">
                        <h2 class="ontario-card__heading">
                            <a [routerLink]="['violenceandharassment']">{{'Safetyboard.Violence' | translate}}</a>
                        </h2>
                        <div class="ontario-card__description">
                            <p innerHTML="{{'Safetyboard.ViolenceSummary' | translate}}"></p>
                        </div>
                    </div>
                </li>
                <li class="ontario-card ontario-card--light  ontario-card--position-vertical  ">
                    <div class="ontario-card__image-container">
                        <img class="ontario-card__image" src="/assets/images/Occupational-Health-&-SafetyAct.png"
                            style="height: 174px;"
                            alt="{{'Safetyboard.OHSAAlt' | translate}}">
                    </div>
                    <div class="ontario-card__text-container ontario-card--image-true">
                        <h2 class="ontario-card__heading">
                            <a [routerLink]="['ohsa']" innerHtml="{{'Safetyboard.OHSA' | translate}}"></a>
                        </h2>
                        <div class="ontario-card__description">
                            <p innerHTML="{{'Safetyboard.OHSASummary' | translate}}"></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <ul class="ontario-card__container ontario-card--cards-per-row-3">
                <li class="ontario-card ontario-card--light  ontario-card--position-vertical  ">
                    <div class="ontario-card__image-container">
                        <img class="ontario-card__image" src="/assets/images/Prevention-Starts-Here-Poster.png"
                            style="height: 174px;"
                            alt="{{'Safetyboard.PreventionAlt' | translate}}">
                    </div>
                    <div class="ontario-card__text-container ontario-card--image-true">
                        <h2 class="ontario-card__heading">
                            <a [routerLink]="['prevention']">{{'Safetyboard.Prevention' | translate}}</a>
                        </h2>
                        <div class="ontario-card__description">
                            <p innerHTML="{{'Safetyboard.PreventionSummary' | translate}}"></p>
                        </div>
                    </div>
                </li>
                <li class="ontario-card ontario-card--light  ontario-card--position-vertical  ">
                    <div class="ontario-card__image-container">
                        <img class="ontario-card__image" src="/assets/images/Employment-Standards-Poster.png"
                            style="height: 174px;"
                            alt="{{'Safetyboard.ESPAlt' | translate}}">
                    </div>
                    <div class="ontario-card__text-container ontario-card--image-true">
                        <h2 class="ontario-card__heading">
                            <a [routerLink]="['esp']">{{'Safetyboard.ESPLink' | translate}}</a>
                        </h2>
                        <div class="ontario-card__description">
                            <p innerHTML="{{'Safetyboard.ESPLinkSummary' | translate}}"></p>
                        </div>
                    </div>
                </li>
                <li *ngIf="isWSIBClient" class="ontario-card ontario-card--light  ontario-card--position-vertical  ">
                    <div class="ontario-card__image-container">
                        <img class="ontario-card__image" src="/assets/images/WSIB-In-case-of-Injury-Poster.png"
                            style="height: 174px;" alt="{{'Safetyboard.WSIBAlt' | translate}}">
                    </div>
                    <div class="ontario-card__text-container ontario-card--image-true">
                        <h2 class="ontario-card__heading">
                            <a [routerLink]="['wsib']">{{'Safetyboard.WSIBSPosterLink' | translate}}</a>
                        </h2>
                        <div class="ontario-card__description">
                            <p innerHTML="{{'Safetyboard.WSIBSPosterLinkSummary' | translate}}"></p>
                        </div>
                    </div>
                </li>
                <li class="ontario-card ontario-card--light  ontario-card--position-vertical  ">
                    <div class="ontario-card__image-container">
                        <img class="ontario-card__image" src="/assets/images/Joint-Heath-&-Safety-Committee-Members.png"
                            style="height: 174px;"
                            alt="{{'Safetyboard.ESPLink' | translate}}">
                    </div>
                    <div class="ontario-card__text-container ontario-card--image-true">
                        <h2 class="ontario-card__heading">
                            <a [routerLink]="['jhsc']">{{'Safetyboard.JHSC' | translate}}</a>
                        </h2>
                        <div class="ontario-card__description">
                            <p innerHTML="{{'Safetyboard.JHSCSummary' | translate}}"></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>    
</div>