import { Component, forwardRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { HomepageComponent } from '../homepage/homepage.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FeedbackStartingDialogComponent } from '../feedback-starting-dialog/feedback-starting-dialog.component';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { FeedbackModel } from 'src/app/models/feedback.model';
import { Router } from '@angular/router';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';

@Component({
  selector: 'app-feedback-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FormsModule, HomepageComponent, TranslateModule, forwardRef(() => FeedbackStartingDialogComponent)],
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent {
  data = inject(MAT_DIALOG_DATA);
  submitButtonClicked: boolean = false;
  xssCheckPassed: boolean = true;
  checkedFeedback:string = "";

  constructor(public dialogRef: MatDialogRef<HomepageComponent>,
    private feedbackService: FeedbackService, 
    public dialog: MatDialog, 
    public router: Router,
    public translate: TranslateService,
    private validationService: ValidationService){}

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onBackClick(): void {
    this.dialogRef.close();
    this.dialog.open(FeedbackStartingDialogComponent);
  }
  
  submitClick() {
    this.submitButtonClicked = true;
    this.checkedFeedback = this.data.feedback;
    this.xssCheckPassed = this.validationService.hasPassedXssValidation(this.checkedFeedback);

    if (this.data.feedback.trim() !== '' && this.data.feedback.length <= 1000 && this.xssCheckPassed) {
      let userFeedback: FeedbackModel = { feedbackText: this.data.feedback, category: this.data.category, root: this.router.url, language: this.translate.currentLang }
        this.feedbackService.createFeedback(userFeedback).subscribe({
          next: () => {
            this.dialogRef.close(this.data);
          },
          error: (errors) =>{
            if(errors.errors?.Message === ValidationService.ServerXssError){
              this.submitButtonClicked = true;
              this.xssCheckPassed = false;
            } else if(errors.errors?.message === ValidationService.ServerValidationErrors){
              this.submitButtonClicked = true;
            }
          }
        });
    }
  }  
}

