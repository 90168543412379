import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Step1Model } from 'src/app/models/step1.model';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { BusinessService } from 'src/app/services/business/business.service';

@Component({
  selector: 'app-additionalsupport',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './additionalsupport.component.html',
  styleUrls: ['./additionalsupport.component.scss']
})
export class AdditionalsupportComponent {
  constructor(private onboardingService: OnboardingService, private translate: TranslateService, private businessService: BusinessService) {}
  step1: Step1Model = {
    BusinessName:'',
    BusinessSector: '',
    OtherSector: '',
    NumOfWorkers: '',
    ForeignWorkers: null,
    PartTimeWorkers: null,
    Under25: null,
    IsWSIBClient: null,
    HasDesignatedSubstances: null,
    DesignatedSubstances: new Array<string>()
  } as Step1Model;
  hasDivingActivity: boolean = false
  hasWindowCleaningActivity: boolean = false

  ngOnInit() {
    this.onboardingService.getStep1Answers().subscribe(
      (initialAnswers) => {
        if (initialAnswers != null) {
            this.step1.BusinessName = initialAnswers.businessName;
            this.step1.BusinessSector = initialAnswers.businessSector;
            this.step1.IsWSIBClient = initialAnswers.isWSIBClient;
            this.step1.ForeignWorkers = initialAnswers.foreignWorkers;
            this.step1.NumOfWorkers = initialAnswers.numOfWorkers;
            this.step1.PartTimeWorkers = initialAnswers.partTimeWorkers;
            this.step1.Under25 = initialAnswers.under25;
            this.step1.Step1Completed = initialAnswers.step1Completed;
            this.step1.OnboardingCompleted = initialAnswers.onboardingCompleted;
            this.step1.HasDesignatedSubstances = initialAnswers.hasDesignatedSubstances;
            this.step1.DesignatedSubstances = initialAnswers.designatedSubstances;
            this.step1.OtherSector = initialAnswers.otherSector;          
        }
      });

      this.businessService.getBusinessActivities().subscribe(data => {
        this.hasDivingActivity = data.some((item: any) => item.activity.name.includes('Diving'));
        this.hasWindowCleaningActivity = data.some((item: any) => item.activity.name.includes('Cleaning windows'));

      })
  }
  
}
