<span class="hide-for-print breadcrumb-container" [ngStyle]="{'display': (breadcrumbsService.showBreadcrumbs$ | async) ? 'block' : 'none'}">
  <div id="block-breadcrumbs">
    <div class="row">
      <div class="column">
        <nav aria-label="Breadcrumb navigation">
          <ul class="breadcrumbs">
            <ng-container *ngFor="let breadcrumb of this.breadcrumbsService.breadcrumbs$ | async; let last = last">
              <ng-container *ngIf="!last; else lastCrumb">
                <li><a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a></li>
              </ng-container>
              <ng-template #lastCrumb>
                <li>{{ breadcrumb.label }}</li>
              </ng-template>
            </ng-container>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</span>
