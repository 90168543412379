<div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                <!-- <div *ngIf="validationFailed" class="ontario-alert ontario-alert--error">
                    <div class="ontario-alert__header">
                        <div class="ontario-alert__header-icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </div>
                        <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                    </div>
                    <div class="ontario-alert__body">
                        <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                        <ul>
                            <li *ngIf="howGetSickEmpty"><a href="javascript:{}" (click)="scrollToElement('howGetSickLabel')">{{'HazardTemplate.HowGetSickField' | translate}} {{'HardcodedGlobals.CannotEmpty' | translate}}</a></li>
                            <li *ngIf="howGetSickTooLong"><a href="javascript:{}" (click)="scrollToElement('howGetSickLabel')">{{'HazardTemplate.HowGetSickField' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 100} }}</a></li>
                            <li *ngIf="howGetSickInvalid"><a href="javascript:{}" (click)="scrollToElement('howGetSickLabel')">{{'HazardTemplate.HowGetSickField' | translate}} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                            <li *ngIf="likelyAffectHealthEmpty"><a href="javascript:{}" (click)="scrollToElement('likelyAffectHealthLabel')">{{'HardcodedGlobals.OptionNotSelected' | translate}} {{'HazardTemplate.LikelyAffectHealthField' | translate}}.</a></li>
                            <li *ngIf="badlyGetHurtEmpty"><a href="javascript:{}" (click)="scrollToElement('badlyGetHurtLabel')">{{'HardcodedGlobals.OptionNotSelected' | translate}} {{'HazardTemplate.BadlyGetHurtField' | translate}}.</a></li>
                            <li *ngIf="legalRequirementsEmpty"><a href="javascript:{}" (click)="scrollToElement('legalRequirementsLabel')">{{'HardcodedGlobals.OptionNotSelected' | translate}} {{'HazardTemplate.LegalRequirementsField' | translate}}.</a></li>
                        </ul>
                    </div>
                </div> -->
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--without-back-button">
                            <span class="ontario-h4">{{'Stepper.Step' | translate}}&nbsp;1 {{'Stepper.Of' | translate}}&nbsp;2</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <h1 class="title">
                {{translate.currentLang === 'en' ? businessModule.module.name : businessModule.module.nameFR}} - {{'HazardTemplate.Assess' | translate}}
            </h1>
            <div class="ontario-padding-bottom-24-!" innerHtml="{{translate.currentLang === 'en' ? section.summary : section.summaryFR}}"></div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="ontario-columns">
            <label class="ontario-label" for="howGetSick" id="howGetSickLabel">
                {{'HazardTemplate.HowGetSick' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </label>
            <div *ngIf="howGetSickEmpty" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HazardTemplate.HowGetSickField' | translate}} {{'HardcodedGlobals.CannotEmpty' | translate}}
                </span>
            </div>
            <div *ngIf="howGetSickTooLong" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HazardTemplate.HowGetSickField' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 100} }}
                </span>
            </div>
            <div *ngIf="howGetSickInvalid" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HazardTemplate.HowGetSickField' | translate}} {{'HardcodedGlobals.InvalidError' | translate}}
                </span>
            </div>
            <input class="ontario-input" type="text" id="howGetSick" [(ngModel)]="howGetSick" [class.ontario-input__error]="howGetSickEmpty || howGetSickTooLong || howGetSickInvalid">
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <label class="ontario-label" for="likelyAffectHealth" id="likelyAffectHealthLabel">
                {{'HazardTemplate.LikelyAffectHealth' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </label>
            <div *ngIf="likelyAffectHealthEmpty" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.OptionNotSelected' | translate}} {{'HazardTemplate.LikelyAffectHealthField' | translate}}.
                </span>
            </div>
            <select class="ontario-input ontario-dropdown" id="likelyAffectHealth" name="likelyAffectHealth-select" aria-label="How likely is the hazard to affect worker health and safety-select" [(ngModel)]="likelyAffectHealth" [class.ontario-input__error]="likelyAffectHealthEmpty">
                <option value="" selected>{{'HardcodedGlobals.AtLeastOne' | translate}}</option>
                <option value="Highly Unlikely">{{'HazardTemplate.HighlyUnlikely' | translate}}</option>
                <option value="Unlikely">{{'HazardTemplate.Unlikely' | translate}}</option>
                <option value="Neutral">{{'HazardTemplate.Neutral' | translate}}</option>
                <option value="Likely">{{'HazardTemplate.Likely' | translate}}</option>
                <option value="Highly Likely">{{'HazardTemplate.HighlyLikely' | translate}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <label class="ontario-label" for="badlyGetHurt" id="badlyGetHurtLabel">
                {{'HazardTemplate.BadlyGetHurt' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </label>
            <div *ngIf="badlyGetHurtEmpty" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.OptionNotSelected' | translate}} {{'HazardTemplate.BadlyGetHurtField' | translate}}.
                </span>
            </div>
            <select class="ontario-input ontario-dropdown" id="badlyGetHurt" name="badlyGetHurt-select" aria-label="How badly could the worker get hurt or sick-select" [(ngModel)]="badlyGetHurt" [class.ontario-input__error]="badlyGetHurtEmpty">
                <option value="" selected>{{'HardcodedGlobals.AtLeastOne' | translate}}</option>
                <option value="1">1 ({{'HazardTemplate.Least' | translate}})</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5 ({{'HazardTemplate.Greatest' | translate}})</option>
            </select>
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns">
            <fieldset class="ontario-fieldset">
                <legend  class="ontario-fieldset__legend" for="radio-buttons" id="legalRequirementsLabel">
                    {{'HazardTemplate.LegalRequirements' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                </legend >
                <div *ngIf="legalRequirementsEmpty" class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HardcodedGlobals.OptionNotSelected' | translate}} {{'HazardTemplate.LegalRequirementsField' | translate}}.
                    </span>
                </div>
                <div class="ontario-radios">
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-1" name="radio-buttons" type="radio" value="Yes" [(ngModel)]="legalRequirements">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-1">
                            {{'HardcodedGlobals.Yes' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-2" name="radio-buttons" type="radio" value="No" [(ngModel)]="legalRequirements">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-2">
                            {{'HardcodedGlobals.No' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-3" name="radio-buttons" type="radio" value="Not Sure" [(ngModel)]="legalRequirements">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-3">
                            Not Sure
                        </label>
                    </div>
                </div>
            </fieldset>
        </div>
    </div> -->
    <div class="row">
        <div class="ontario-columns">
            <button (click)="back()" class="ontario-button ontario-button--secondary">
                {{ 'Buttons.Back' | translate}}
            </button>
            <button (click)="saveAndContinue()" class="ontario-button ontario-button--primary">
                {{ 'Buttons.SaveAndContinue' | translate}}
            </button>
        </div>  
    </div>
  </div>