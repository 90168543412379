import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private breadcrumbsSubject = new BehaviorSubject<{ label: string, url: string }[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateBreadcrumbs();
      }
    });
  }

  private showBreadcrumbsSubject = new BehaviorSubject<boolean>(true);
  showBreadcrumbs$ = this.showBreadcrumbsSubject.asObservable();
  
  updateShowBreadcrumbs(value: Observable<boolean>) {
    value.subscribe((result: boolean) => {
      this.showBreadcrumbsSubject.next(result);
    })
  }

  private updateBreadcrumbs(): void {
    const breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: { label: string, url: string }[] = []
  ): { label: string, url: string }[] {
    const routeConfig = route.routeConfig;

    if (routeConfig && routeConfig.data) {
      const label = routeConfig.data['title'];
      const path = routeConfig.path === '' ? '' : `/${routeConfig.path}`;
      url += path;
      breadcrumbs.push({ label, url });
    }

    if (route.firstChild) {
      return this.createBreadcrumbs(route.firstChild, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}