import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { filter } from 'rxjs';
import { AnswerService } from 'src/app/services/answer/answer.service';
import { BusinessModuleModel, SectionModel } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation/validation.service';

declare function scrollToError(): void

@Component({
  selector: 'app-hazard-step1',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './hazard-step1.component.html',
  styleUrls: ['./hazard-step1.component.scss']
})
export class HazardStep1Component {
  hazardAnswers: { value: string, validationFailed: boolean }[] = [{ value: '', validationFailed: false }];
  returnId: string = "";
  businessModule: BusinessModuleModel = {
    id: '',
    completed: false,
    dismissed: false,
    lastUpdatedBy: '',
    lastUpdatedOn: '',
    answers: [],
    module: {
      id: '',
      name: '',
      nameFR: '',
      summary: '',
      summaryFR: '',
      type: '',
      priority: 0,
      imageURL: '',
      dismissible: true
    },
    completedSectionsCount: 0
  };
  section: SectionModel = {
    id: '',
    summary: '',
    summaryFR: '',
    priority: 0,
    type: '',
    moduleId: '',
  };
  hazardId: string = '';
  howGetSick: string = "";
  likelyAffectHealth: string = "";
  badlyGetHurt: string = "";
  legalRequirements: string = '';
  howGetSickEmpty: boolean = false;
  howGetSickTooLong: boolean = false;
  howGetSickInvalid: boolean = false;
  likelyAffectHealthEmpty: boolean = false;
  badlyGetHurtEmpty: boolean = false;
  legalRequirementsEmpty: boolean = false;
  validationFailed: boolean = false;

  constructor(public translate: TranslateService, private activatedRoute: ActivatedRoute, private businessModuleService: BusinessModuleService, private router: Router, private answerService: AnswerService, private validationService: ValidationService) { 
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const focusedElement = document.activeElement as HTMLElement;
      focusedElement.blur();
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hazardId = params['id'];
      this.businessModuleService.getModule(this.hazardId).subscribe(businessModule => {
        this.businessModule = businessModule;
        this.businessModuleService.getSection(this.hazardId, "1").subscribe(section => {
          this.section = section;
          /* this.answerService.getAnswers(section.id, businessModule.id).subscribe(data => {
            for (const answer of data) {
              switch (answer.questionId) {
                case 1: 
                  this.howGetSick = answer.answerText;
                  break;
                case 2: 
                  this.likelyAffectHealth = answer.answerText;
                  break;
                case 3: 
                  this.badlyGetHurt = answer.answerText;
                  break;
                case 4: 
                  this.legalRequirements = answer.answerText;
                  break;
              }
            }
          }); */
        });
      });
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['return'] != undefined)
        this.returnId = params['return'];
    });
  }

  resetFields() {
    this.howGetSickEmpty = false;
    this.howGetSickTooLong = false;
    this.howGetSickInvalid = false;
    this.likelyAffectHealthEmpty = false;
    this.badlyGetHurtEmpty = false;
    this.legalRequirementsEmpty = false;
    this.validationFailed = false;
  }

  saveAndContinue() {
    /* let answerText: {answerText: string, questionId: number, answerOrder:number}[] = [];
    this.resetFields();

    if (this.howGetSick.trim() === '') {
      this.validationFailed = true;
      this.howGetSickEmpty = true
    } else if(!this.validationService.hasPassedXssValidation(this.howGetSick)){
      this.validationFailed = true;
      this.howGetSickInvalid = true;
    } else if (this.howGetSick.trim().length > 100) {
      this.validationFailed = true;
      this.howGetSickTooLong = true;
    }

    if (this.likelyAffectHealth.trim() === '') {
      this.validationFailed = true;
      this.likelyAffectHealthEmpty = true;
    }

    if (this.badlyGetHurt.trim() === '') {
      this.validationFailed = true;
      this.badlyGetHurtEmpty = true;
    }

    if (this.legalRequirements.trim() === '') {
      this.validationFailed = true;
      this.legalRequirementsEmpty = true;
    }

    if (!this.validationFailed) {
      answerText.push({answerText: this.howGetSick, questionId: 1,answerOrder: 1});
      answerText.push({answerText: this.likelyAffectHealth, questionId: 2,answerOrder: 2});
      answerText.push({answerText: this.badlyGetHurt, questionId: 3,answerOrder: 3});
      answerText.push({answerText: this.legalRequirements, questionId: 4,answerOrder: 4}); */
      this.businessModuleService.addCompletedBusinessModuleSection(this.businessModule.id, this.section.id).subscribe(_ => { });
      // this.answerService.addAnswers(this.section.id, this.businessModule.id, answerText).subscribe(() => {
        this.businessModuleService.updateCompleted(this.businessModule.module.id, false).subscribe(() => {
          if (this.returnId != "")
            this.router.navigate(['../../', 2, this.hazardId], { queryParams: {return: this.returnId}, relativeTo: this.activatedRoute });
          else 
            this.router.navigate(['../../', 2, this.hazardId], { relativeTo: this.activatedRoute });
        });
     /*  });
    } else
      scrollToError(); */
  }

  back() {
    if (this.returnId != "")
      this.router.navigate(['../../view', this.hazardId], { queryParams: {return: this.returnId}, relativeTo: this.activatedRoute });
    else
      this.router.navigate(['../../view', this.hazardId], { relativeTo: this.activatedRoute });
  }

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
