import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { FeedbackModel } from 'src/app/models/feedback.model';

@Component({
  selector: 'app-dismisshazard',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, MatDialogModule],
  templateUrl: './dismisshazard.component.html',
  styleUrls: ['./dismisshazard.component.scss']
})
export class DismisshazardComponent {
  data = inject(MAT_DIALOG_DATA);
  submitButtonClicked: boolean = false;
  xssCheckPassed: boolean = true;

  constructor(public currentDialogRef: MatDialogRef<DismisshazardComponent>, 
    public dialog: MatDialog, 
    public translate: TranslateService, 
    public router: Router,
    private feedbackService: FeedbackService,
    private businessModuleService: BusinessModuleService,
    private validationService: ValidationService) { }

  onCloseClick(): void {
    this.currentDialogRef.close();
  }

  deletePlan() {
    this.currentDialogRef.close();
  }

  submitClick() {
    this.submitButtonClicked = true;
    this.xssCheckPassed = this.validationService.hasPassedXssValidation(this.data.feedback);

    if(this.data.feedback.length <= 1000 && this.xssCheckPassed) {
      let userFeedback: FeedbackModel = { feedbackText: this.data.feedback, category: 'Hazard Dismiss', root: this.router.url, language: this.translate.currentLang }
      this.feedbackService.createFeedback(userFeedback).subscribe({
        next: () => {
          this.businessModuleService.updateDismissed(this.data.hazardId, true).subscribe(() => {
            this.currentDialogRef.close(this.data);
          });
        },
        error: (errors) =>{
          if(errors.errors?.Message === ValidationService.ServerXssError){
            this.submitButtonClicked = true;
            this.xssCheckPassed = false;
          } else if(errors.errors?.message === ValidationService.ServerValidationErrors){
            this.submitButtonClicked = true;
          }
        }
      }); 
    }      
  }
}
