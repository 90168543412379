<div id="warningDialog" class="session-confirm">
    <header>
      <h1 mat-dialog-title class="confirm-close" *ngIf="!isError">
        <div class="icon-background">
        </div>
        {{"General.AreYouThere?" | translate}}
      </h1>
      <h1 mat-dialog-title class="confirm-close" *ngIf="isError">
        {{"General.SessionExpired" | translate}}
      </h1>
      <hr/>
    </header>
    <mat-dialog-content>
      <div *ngIf="!isError" id="session-msg" class="prewrap-text"
           [innerHTML]="'General.YourSessionHasBeenInactiveForMinutesPlural' | translate : {mins: mins, inactive:inactive}"></div>
      <div *ngIf="isError" id="session-end-msg" class="prewrap-text"
           [innerHTML]="'General.YourSessionHasExpiredDueToInactivity' | translate : {inactive:inactive}"></div>
      <ng-container>
        <button id="dialog-cancel" class="btn ontario-button--primary ontario-button confirm-button" (click)="close()">
          {{"General.Ok" | translate}}
        </button>
      </ng-container>
    </mat-dialog-content>
  </div>
  