<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                <div *ngIf="validationFailed" class="ontario-alert ontario-alert--error" id="errorHeading" #errorHeading tabindex="0">
                    <div class="ontario-alert__header">
                        <div class="ontario-alert__header-icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </div>
                        <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                    </div>
                    <div class="ontario-alert__body">
                        <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                        <ul> 
                            <li *ngIf="invalidCharacterError"><a href="javascript:{}" (click)="scrollToElement('planNameLabel')">{{'HardcodedGlobals.OneOfInvalidError' | translate}}</a></li>
                            <li *ngIf="planNameTooLong"><a href="javascript:{}" (click)="scrollToElement('planNameLabel')">{{'NewPlanStep1.NameOfSafetyPlanTooLong' | translate}}</a></li>
                            <li *ngIf="planNameInvalid"><a href="javascript:{}" (click)="scrollToElement('planNameLabel')">{{'NewPlanStep1.NameOfSafetyPlanInvalidError' | translate}}</a></li>
                            <li *ngIf="contactNameEmpty"><a href="javascript:{}" (click)="scrollToElement('contactNameLabel')">{{'NewPlanStep1.ContactNameCannotEmpty' | translate}}</a></li>
                            <li *ngIf="contactNameInvalid"><a href="javascript:{}" (click)="scrollToElement('contactNameLabel')">{{'NewPlanStep1.ContactNameInvalidError' | translate}}</a></li>
                            <li *ngIf="contactNameTooLong"><a href="javascript:{}" (click)="scrollToElement('contactNameLabel')">{{'NewPlanStep1.ContactNameTooLong' | translate }}</a></li>
                            <li *ngIf="contactNumberEmpty"><a href="javascript:{}" (click)="scrollToElement('contactNumberLabel')">{{'NewPlanStep1.ContactNumberCannotEmpty' | translate}}</a></li>
                            <li *ngIf="contactNumberTooLong"><a href="javascript:{}" (click)="scrollToElement('contactNumberLabel')">{{'NewPlanStep1.ContactNumberTooLong' | translate }}</a></li>
                            <li *ngIf="contactNumberInvalid"><a href="javascript:{}" (click)="scrollToElement('contactNumberLabel')">{{'NewPlanStep1.ContactNumberInvalidError' | translate}}</a></li>
                            <li *ngIf="contactEmailEmpty"><a href="javascript:{}" (click)="scrollToElement('contactEmailLabel')">{{'NewPlanStep1.ContactEmailCannotEmpty' | translate}}</a></li>
                            <li *ngIf="contactEmailTooLong"><a href="javascript:{}" (click)="scrollToElement('contactEmailLabel')">{{'NewPlanStep1.ContactEmailTooLong' | translate }}</a></li>
                            <li *ngIf="contactEmailXssFailed"><a href="javascript:{}" (click)="scrollToElement('contactEmailLabel')">{{'NewPlanStep1.ContactEmailInvalidError' | translate}}</a></li>
                            <li *ngIf="contactEmailInvalid"><a href="javascript:{}" (click)="scrollToElement('contactEmailLabel')">{{'NewPlanStep1.InvalidEmail' | translate}}</a></li>
                            <li *ngIf="noteTooLong"><a href="javascript:{}" (click)="scrollToElement('notesLabel')">{{'NewPlanStep1.NotesTooLong' | translate }}</a></li>
                            <li *ngIf="noteInvalid"><a href="javascript:{}" (click)="scrollToElement('notesLabel')">{{'NewPlanStep1.NotesInvalidError' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--without-back-button">
                            <span class="ontario-h4">{{'Stepper.Title1' | translate}}</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <h1 class="title">
                {{'NewPlanStep1.BasicInformation' | translate}}
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="sr-only">Plan Name</legend>
                <label class="ontario-label" for="planname" id="planNameLabel">
                    {{'NewPlanStep1.SafetyPlanName' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
                </label>
                <div *ngIf="planNameTooLong" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.NameOfSafetyPlanTooLong' | translate}}
                    </span>
                </div>
                <div *ngIf="planNameInvalid" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.NameOfSafetyPlanInvalidError' | translate}}
                    </span>
                </div>
                <input class="ontario-input" type="text" id="planname" [(ngModel)]="planNameValue" [class.ontario-input__error]="planNameTooLong || invalidCharacterError || planNameInvalid">
        </fieldset>
        </div>
    </div>

    
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="sr-only">Contact Name</legend>
                <label class="ontario-label" for="contactname" id="contactNameLabel">
                    {{'NewPlanStep1.ContactName' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                </label>
                <div *ngIf="contactNameEmpty" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactNameCannotEmpty' | translate}}
                    </span>
                </div>
                <div *ngIf="contactNameInvalid" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactNameInvalidError' | translate}}
                    </span>
                </div>
                <div *ngIf="contactNameTooLong" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactNameTooLong' | translate }}
                    </span>
                </div>
                <input class="ontario-input" type="text" id="contactname" [(ngModel)]="contactNameValue" [class.ontario-input__error]="contactNameInvalid || contactNameTooLong || contactNameEmpty || invalidCharacterError">
        </fieldset>
        </div>
        
    </div>

    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="sr-only">Contact Number</legend>
                <label class="ontario-label" for="contactnumber" id="contactNumberLabel">
                    {{'NewPlanStep1.ContactNumber' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                </label>
                <div *ngIf="contactNumberEmpty" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactNumberCannotEmpty' | translate}}
                    </span>
                </div>
                <div *ngIf="contactNumberInvalid" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactNumberInvalidError' | translate}}
                    </span>
                </div>
                <div *ngIf="contactNumberTooLong" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactNumberTooLong' | translate }}
                    </span>
                </div>
                <input class="ontario-input" type="text" id="contactnumber" [(ngModel)]="contactNumberValue" [class.ontario-input__error]="contactNumberInvalid || contactNumberTooLong || contactNumberEmpty || invalidCharacterError">
        </fieldset>
        </div>
        
    </div>

    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="sr-only">Contact Email</legend>
                <label class="ontario-label" for="contactemail" id="contactEmailLabel">
                    {{'NewPlanStep1.ContactEmail' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                </label>
                <div *ngIf="contactEmailEmpty" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactEmailCannotEmpty' | translate }}
                    </span>
                </div>
                <div *ngIf="contactEmailTooLong" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactEmailTooLong' | translate }}
                    </span>
                </div>
                <div *ngIf="contactEmailXssFailed" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.ContactEmailInvalidError' | translate }}
                    </span>
                </div>
                <div *ngIf="contactEmailInvalid" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.InvalidEmail' | translate}}
                    </span>
                </div>
                <input class="ontario-input" type="text" id="contactemail" [(ngModel)]="contactEmailValue" [class.ontario-input__error]="contactEmailEmpty || contactEmailTooLong || contactEmailXssFailed || contactEmailInvalid || invalidCharacterError">
        </fieldset>
        </div>
        
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="sr-only">Notes</legend>
                <label class="ontario-label" for=" notes" id="notesLabel">
                    {{'NewPlanStep1.Notes' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
                </label>
                <div *ngIf="noteTooLong" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.NotesTooLong' | translate }}
                    </span>
                </div>
                <div *ngIf="noteInvalid" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'NewPlanStep1.NotesInvalidError' | translate}}
                    </span>
                </div>
                <textarea class="ontario-input ontario-textarea" type="text" id=" notes" [(ngModel)]="notesValue" [class.ontario-input__error]="noteInvalid || noteTooLong || invalidCharacterError"></textarea>
        </fieldset>
        <br>
        <button (click)="saveAndContinue()" class="ontario-button ontario-button--primary">
            {{ 'Buttons.SaveAndContinue' | translate }}
        </button>        
        </div>

    </div>
  </div>

