<div id="warningDialog" class="session-confirm">
    <header>
      <h1 mat-dialog-title  class="confirm-close">
        <img class="fa fa-exclamation-circle-error" alt="" [src]="'assets/images/red-with-white-exclamation.png'">
        <span class="spanHeader">{{"Error.GeneralErrorHeader" | translate}}</span>
      </h1>
      <hr />
    </header>
    <mat-dialog-content>
      <div id="session-end-msg" class="prewrap-text" [innerHTML]="'Error.GeneralErrorMessage' | translate"></div>
      <ng-container>
        <button id="dialog-cancel" class="btn ontario-button--primary ontario-button confirm-button" (click)="close()">
          {{"General.Ok" | translate}}
        </button>
      </ng-container>
    </mat-dialog-content>
  </div> 