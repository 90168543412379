<div class="ontario-form-group">
    <div class="header">
        <div class="centered-text"><h5>{{'Feedback.ProvideFeedback' | translate}}</h5></div>
        <div class="closeButton">
            <button (click)="onCloseClick()" aria-label="Close">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-close"></use>
                </svg>
            </button>
        </div>
    </div>
    <div class="feedback-button-group">
        <button (click)="openDialog('Issue')" class="ontario-button ontario-button--primary">
            {{'Feedback.IssueHeading' | translate}}
        </button>
        <button (click)="openDialog('Suggestion')" class="ontario-button ontario-button--primary">
            {{'Feedback.SuggestionHeading' | translate}}
        </button>
        <button (click)="openDialog('Other')" class="ontario-button ontario-button--primary">
            {{'Feedback.OtherHeading' | translate}}
        </button>
    </div>
</div>