import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { Step1Model } from 'src/app/models/models';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

declare function scrollToError(): void

@Component({
  selector: 'app-step1',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component {
  step1: Step1Model = {
    BusinessName:'',
    BusinessSector: '',
    OtherSector: '',
    NumOfWorkers: '',
    ForeignWorkers: null,
    PartTimeWorkers: null,
    Under25: null,
    IsWSIBClient: null,
    HasDesignatedSubstances: null,
    DesignatedSubstances: new Array<string>()
  } as Step1Model;

  selectedOptions: string[] = [];
  
  businessMaxLength: number = 100;
  otherSectorMaxLength: number = 100;
  validationFailed: boolean = false;
  businessNameTooLong: boolean = false;
  businessNameInvalid: boolean = false;
  otherSectorTooLong: boolean = false;
  otherSectorInvalid: boolean = false;
  businessSectorEmpty: boolean = false;
  numOfWorkersInvalid: boolean = false;
  foreignWorkersInvalid: boolean = false;
  partTimeWorkersInvalid: boolean = false;
  under25Invalid: boolean = false;
  wsibClientInvalid: boolean = false;
  dsrInvalid: boolean = false;
  dsrOptionsInvalid: boolean = false;
  showOptionalField: boolean = false;
  businessNameField: string = '';
  otherSectorField: string = '';
  dsrField: string = '';
  numberOfWorkersField: string = '';
  @ViewChild('errorHeading', {read: ElementRef}) errorHeading! : ElementRef;
  constructor(
    public authService: AuthService, 
    public translate: TranslateService, 
    public router: Router,
    private onboardingService: OnboardingService, 
    private validationService: ValidationService, 
    private spinnerService: SpinnerService,
    private changeDetector : ChangeDetectorRef) { }

    toggleExpander(expandableItem: any) {
      let item = expandableItem.currentTarget;
      item.parentNode.classList.toggle("ontario-expander--active");
      var content = item.parentNode.querySelector(
        "[data-toggle='ontario-expander-content']"
      );
      content.classList.toggle("ontario-expander__content--opened");
      content.classList.contains("ontario-expander__content--opened")
        ? content.setAttribute("aria-hidden", "false")
        : content.setAttribute("aria-hidden", "true");
      item.parentNode.classList.contains("ontario-expander--active")
        ? item.setAttribute("aria-expanded", "true")
        : item.setAttribute("aria-expanded", "false");
    }
  
  ngOnInit() {
    this.loadUserInitialAnswers();
    this.businessNameField = this.translate.instant('Step1.BusinessNameField');
    this.otherSectorField = this.translate.instant('Step1.BusinessSectorField');
    this.dsrField = this.translate.instant('Step1.DSRField');
    this.numberOfWorkersField = this.translate.instant('Step1.NumberOfWorkersField');
  }

  loadUserInitialAnswers() {
    this.onboardingService.getStep1Answers().subscribe(
      (initialAnswers) => {
        if (initialAnswers != null) {
          if (initialAnswers.step1Completed === true) {
            this.step1.BusinessName = initialAnswers.businessName;
            this.step1.BusinessSector = initialAnswers.businessSector;
            this.step1.IsWSIBClient = initialAnswers.isWSIBClient;
            this.step1.ForeignWorkers = initialAnswers.foreignWorkers;
            this.step1.NumOfWorkers = initialAnswers.numOfWorkers;
            this.step1.PartTimeWorkers = initialAnswers.partTimeWorkers;
            this.step1.Under25 = initialAnswers.under25;
            this.step1.Step1Completed = initialAnswers.step1Completed;
            this.step1.OnboardingCompleted = initialAnswers.onboardingCompleted;
            this.step1.HasDesignatedSubstances = initialAnswers.hasDesignatedSubstances;
            this.step1.DesignatedSubstances = initialAnswers.designatedSubstances;
            this.selectedOptions = initialAnswers.hasDesignatedSubstances === "Yes" ? initialAnswers.designatedSubstances : [];
            this.step1.OtherSector = initialAnswers.otherSector;          
          }
        }
      });
  }
  
  resetFields() {
    this.validationFailed = false;
    this.businessNameTooLong = false;
    this.businessNameInvalid = false;
    this.businessSectorEmpty = false;
    this.numOfWorkersInvalid = false;
    this.foreignWorkersInvalid = false;
    this.partTimeWorkersInvalid = false;
    this.under25Invalid = false;
    this.wsibClientInvalid = false;
    this.dsrInvalid = false;
    this.dsrOptionsInvalid = false;
  }

  scrollToElement(label: string, control: string = '') {
    const labelElement = document.getElementById(label)
    const focusElement = document.getElementById(control)
    if (labelElement || focusElement) {
      labelElement?.scrollIntoView({ behavior: 'smooth' });
      focusElement?.focus();
    }
  }

  onCheckboxChange(optionValue: string) {
    if (this.selectedOptions.includes(optionValue)) {
      this.selectedOptions = this.selectedOptions.filter(val => val !== optionValue);
    } else {
      this.selectedOptions.push(optionValue);
    }
  }

  isChecked(optionValue: string) {
    return this.selectedOptions.includes(optionValue);
  }

  toggleOptionalField() {
    this.showOptionalField = this.step1.BusinessSector === 'Other';
}

  saveAndContinue() {
    this.resetFields()
    if(!this.validationService.hasPassedXssValidation(this.step1.BusinessName)){
      this.validationFailed = true;
      this.businessNameInvalid = true;
    } else if (this.step1.BusinessName.trim().length > this.businessMaxLength) {
      this.validationFailed = true;
      this.businessNameTooLong = true;
    }

    if(!this.validationService.hasPassedXssValidation(this.step1.OtherSector)){
      this.validationFailed = true;
      this.otherSectorInvalid = true;
    } 
    else{
      this.otherSectorInvalid = false;
    }
    
    if (this.step1.OtherSector.trim().length > this.otherSectorMaxLength) {
      this.validationFailed = true;
      this.otherSectorTooLong = true;
    }
    else{
      this.otherSectorTooLong = false;
    }

    if (this.step1.BusinessSector.trim() === '') {
      this.validationFailed = true;
      this.businessSectorEmpty = true;
    }

    if (!this.step1.NumOfWorkers) {
      this.validationFailed = true;
      this.numOfWorkersInvalid = true;
    }
    
    if (this.step1.ForeignWorkers === null) {
      this.validationFailed = true;
      this.foreignWorkersInvalid = true;
    }

    if (this.step1.PartTimeWorkers === null) {
      this.validationFailed = true;
      this.partTimeWorkersInvalid = true;
    }

    if (this.step1.Under25 === null) {
      this.validationFailed = true;
      this.under25Invalid = true;
    }

    if (this.step1.IsWSIBClient === null) {
      this.validationFailed = true;
      this.wsibClientInvalid = true;
    }

    if(this.step1.HasDesignatedSubstances === null) {
      this.validationFailed = true;
      this.dsrInvalid = true;
    }

    if(this.step1.HasDesignatedSubstances === "Yes" && this.selectedOptions.length === 0) {
      this.validationFailed = true;
      this.dsrOptionsInvalid = true;
    }

    if (this.validationFailed) {
      this.changeDetector.detectChanges();
      this.errorHeading?.nativeElement?.focus();
      this.errorHeading?.nativeElement?.scrollIntoView();
      //scrollToError();
    } else {
      this.step1.DesignatedSubstances = this.step1.HasDesignatedSubstances === "Yes" ? this.selectedOptions : [];
      this.onboardingService.updateBusinessStep1(this.step1).subscribe({
        next: () => {
          this.validationFailed = false;
          if (this.step1.Step1Completed && this.step1.OnboardingCompleted) {
            this.router.navigate([this.translate.currentLang == 'en' ? 'profile' : 'fr/profile']);
          } else {
            this.router.navigate([this.translate.currentLang == 'en' ? 'step2' : 'fr/step2']);
          }
        },
        error: (error) => {
          if(error.error?.Message === ValidationService.ServerXssError) {
            this.validationFailed = true;
            this.businessNameInvalid = true;
            scrollToError();
          }
          if (error.error?.message === "Validation Errors") {
            error.error.errors.forEach((e: { errorCode: string, errorMessage: string, propertyName: string; }) => {
              Object.assign(this, {[e.errorMessage]: true});
            });
            this.validationFailed = true;
            scrollToError();
          }
          this.spinnerService.hide();
        }
      });
    }
  }
}
