import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, of, tap } from "rxjs";
import { AuthService } from "../services/auth/auth.service";
import { inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { SpinnerService } from "../services/spinner/spinner.service";
import { IdleTimeoutService } from "../services/idle-timeout/idle-timeout.service";
import { TranslateService } from "@ngx-translate/core";

let count: number = 0

export function httpInterceptor(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
    const authService = inject(AuthService)
    const spinnerService = inject(SpinnerService)
    const idleTimeoutService = inject(IdleTimeoutService)
    const translateService = inject(TranslateService)
    count++
    if (count == 1) {
      spinnerService.show()
    }

    const language = translateService.currentLang === 'en' ? 'en-CA' : 'fr-CA';
    const langRequest = request.clone({
        headers: request.headers.set('Accept-Language', language)
    })
    
    if (request.url.toString().startsWith(environment.apiBaseUrl) && authService.loggedInStatus.value && (authService.isSessionExpiryValid() || authService.isSessionExpiryNull())) {
        return next(langRequest).pipe(
            tap((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                    let expire = evt.headers.get('Session-Expiry')
                    if (expire != null && expire != undefined) {
                        authService.setSessionExpiry(Number(expire))
                        idleTimeoutService.startTimer()
                    }
                    reduceCounter()
                }
            })
        )
    } else if (authService.loggedInStatus.value && !authService.isSessionExpiryValid()) { 
        authService.gotoLogout()
        return of(null as any)
    } else {
        reduceCounter()
        return next(langRequest)
    }

    function reduceCounter() {
        count--;
        if (count === 0) {
          spinnerService.hide();
        }
    }
}