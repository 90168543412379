import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubheaderService {

  private showSubheaderSubject = new BehaviorSubject<boolean>(true);
  showSubheader$ = this.showSubheaderSubject.asObservable();
  
  constructor() { }
  
  updateShowSubheader(value: Observable<boolean>) {
    value.subscribe((result: boolean) => {
      this.showSubheaderSubject.next(result);
    })
  }

}
