<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{'HealthAndSafetyPartners.Heading' | translate}}
          </h1>          
          <p class="ontario-lead-statement">{{'HealthAndSafetyPartners.Summary' | translate}}</p>
          <div class="new-div ontario-hint-expander__container">
            <button class="ontario-hint-expander__button" id="hint-expander-button-id-1" aria-controls="hint-expander-content" aria-expanded="false" data-toggle="ontario-collapse" (click)="toggleExpander($event)">
                <span class="ontario-hint-expander__button-icon--close"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-chevron-up"></use>
                    </svg></span>
                <span class="ontario-hint-expander__button-icon--open"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-chevron-down"></use>
                    </svg></span>
                {{'HealthAndSafetyPartners.Hint' | translate}}
            </button>
            <div class="ontario-hint-expander__content" id="hint-expander-content-1" aria-labelledby="hint-expander-button-id-1" aria-hidden="true" data-toggle="ontario-expander-content">
                <p innerHtml="{{'HealthAndSafetyPartners.FindHere' | translate}}"></p>
            </div>
    </div>
    <div class="ontario-row">
      <div class="ontario-columns">
        <h4>{{'HealthAndSafetyPartners.SectorBasedHeading' | translate}}</h4>
        <p [innerHTML]="'HealthAndSafetyPartners.SectorBasedDescription' | translate"></p>
        <p [innerHTML]="'HealthAndSafetyPartners.SectorBasedIHSA' | translate"></p>
        <p [innerHTML]="'HealthAndSafetyPartners.SectorBasedPSHSA' | translate"></p>
        <p [innerHTML]="'HealthAndSafetyPartners.SectorBasedWSPS' | translate"></p>
        <p [innerHTML]="'HealthAndSafetyPartners.SectorBasedWSN' | translate"></p>
      </div>
    </div>
    <div class="ontario-row">
      <div class="ontario-columns">
        <h4>{{'HealthAndSafetyPartners.AllSectorsHeading' | translate}}</h4>
        <p [innerHTML]="'HealthAndSafetyPartners.AllSectorsOHCOW' | translate"></p>
        <p [innerHTML]="'HealthAndSafetyPartners.AllSectorsWHSC' | translate"></p>
      </div>
    </div>
    <!-- <div class="ontario-row">
      <div class="ontario-columns">
        <p class="list-header">{{'HealthAndSafetyPartners.EachHealthAndSafetyHeading' | translate}}</p>
        <ul>
          <li>{{'HealthAndSafetyPartners.EachHealthAndSafetyEmployers' | translate}}</li>
          <li>{{'HealthAndSafetyPartners.EachHealthAndSafetyJoint' | translate}}</li>
          <li>{{'HealthAndSafetyPartners.EachHealthAndSafetyMedical' | translate}}</li>
          <li>{{'HealthAndSafetyPartners.EachHealthAndSafetyUnions' | translate}}</li>
        </ul>
      </div>
    </div> -->
    <!-- <div class="ontario-row">
      <div class="ontario-columns">
        <p class="list-header">{{'HealthAndSafetyPartners.AssociationsHeading' | translate}}</p>
        <ul>
          <li>{{'HealthAndSafetyPartners.AssociationsDelivering' | translate}}</li>
          <li>{{'HealthAndSafetyPartners.AssociationsConsultation' | translate}}</li>
          <li>{{'HealthAndSafetyPartners.AssociationsSupporting' | translate}}</li>
        </ul>
      </div>
    </div> -->
</div>
   