import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DismisshazardComponent } from '../dismisshazard/dismisshazard.component';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { BusinessModuleModel } from 'src/app/models/models';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-hazardlanding',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './hazardlanding.component.html',
  styleUrls: ['./hazardlanding.component.scss']
})

export class HazardlandingComponent {
  showSuccessAlert = false;
  returnId: string = "";
  businessModule: BusinessModuleModel = {
    id: '',
    completed: false,
    dismissed: false,
    lastUpdatedBy: '',
    lastUpdatedOn: '',
    answers: [],
    module: {
      id: '',
      name: '',
      nameFR: '',
      summary: '',
      summaryFR: '',
      type: '',
      priority: 0,
      imageURL: '',
      dismissible: true
    },
    completedSectionsCount: 0
  };
  hazardId: string = '';
  dialogRef: any;

  constructor(private activatedRoute: ActivatedRoute, 
    private businessModuleService: BusinessModuleService, 
    public dialog: MatDialog, 
    public authService: AuthService, 
    public translate: TranslateService, 
    public router: Router,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.hazardId = params['id']
      this.loadModule(this.hazardId);
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['return'] != undefined)
        this.returnId = params['return'];
    });
  }

  continue() {
    if (this.businessModule.dismissed) {
      this.businessModuleService.updateDismissed(this.businessModule.module.id, false).subscribe( () =>
        this.router.navigate(['../../1', this.hazardId], { relativeTo: this.activatedRoute })
      );
    } else {
      if(this.returnId != "")
        this.router.navigate(['../../1', this.hazardId], { queryParams: {return: this.returnId}, relativeTo: this.activatedRoute })
      else
        this.router.navigate(['../../1', this.hazardId], { relativeTo: this.activatedRoute })
    }
  }
  
  openDialog() {
    this.dialogRef = this.dialog.open(DismisshazardComponent, {
      data: {
        feedback: "",
        hazardId: this.hazardId,
        errors:[]
      },
    });

    this.dialogRef.afterClosed().subscribe((data: any) => {
      this.spinnerService.hide();
      if (data) {
          this.back();
      }
    });
  }

  loadModule(moduleId: string) {
    this.businessModuleService.getModule(moduleId).subscribe(data => {
      this.businessModule = data;
    });
  }

  back() {
    if (this.returnId != "")
      this.router.navigate(['../../../safetyplans/newplanstep2/', this.returnId], { relativeTo: this.activatedRoute });
    else
      this.router.navigate(['../../../hazards'], { relativeTo: this.activatedRoute });
  }
}