import { Routes } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { localizationGuard } from './guards/localization.guard';
import { authGuard } from './guards/auth.guard';
import { Step1Component } from './components/step1/step1.component';
import { HealthandsafetypledgeComponent } from './components/healthandsafetypledge/healthandsafetypledge.component';
import { ImportantnotesComponent } from './components/importantnotes/importantnotes.component';
import { Step2Component } from './components/step2/step2.component';
import { Step3Component } from './components/step3/step3.component';
import { InjuryreportingComponent } from './components/injuryreporting/injuryreporting.component';
import { HazardlandingComponent } from './components/hazardlanding/hazardlanding.component';
import { HazardStep1Component } from './components/hazard-step1/hazard-step1.component';
import { HazardStep2Component } from './components/hazard-step2/hazard-step2.component';
import { SafetyboardComponent } from './components/safetyboard/safetyboard.component';
import { SafetyplansComponent } from './components/safetyplans/safetyplans.component';
import { Newplanstep1Component } from './components/newplanstep1/newplanstep1.component';
import { Newplanstep2Component } from './components/newplanstep2/newplanstep2.component';
import { Newplanstep3Component } from './components/newplanstep3/newplanstep3.component';
import { Newplanstep4Component } from './components/newplanstep4/newplanstep4.component';
import { HazardsComponent } from './components/hazards/hazards.component';
import { ViolenceandharassmentComponent } from './components/violenceandharassment/violenceandharassment.component';
import { OccupationalHealthAndSafetyActPostComponent } from './components/occupational-health-and-safety-act-post/occupational-health-and-safety-act-post.component';
import { ViewplanComponent } from './components/viewplan/viewplan.component';
import { OHSComponent } from './components/ohs/ohs.component';
import { WSIBComponent } from './components/wsib/wsib.component';
import { EmploymentStandardsComponent } from './components/employment-standards/employment-standards.component';
import { HazardsummaryComponent } from './components/hazardsummary/hazardsummary.component';
import { JHSCComponent } from './components/jhsc/jhsc.component';
import { HealthAndSafetyPartnersComponent } from './components/healthandsafetypartners/healthandsafetypartners.component';
import { EventsandwebinarsComponent } from './components/eventsandwebinars/eventsandwebinars.component';
import { LegalrequirementsComponent } from './components/legalrequirements/legalrequirements.component';
import { HazardCustomComponent } from './components/hazard-custom/hazard-custom.component';
import { ResetComponent } from './components/reset/reset.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AccountSettingsComponent } from './components/workspace-management/account-settings.component';
import { MentalhealthComponent } from './components/mentalhealth/mentalhealth.component';
import { AdditionalsupportComponent } from './components/additionalsupport/additionalsupport.component';
import { TrainingComponent } from './components/training/training.component';
import { resetGuard } from './guards/reset.guard';

export const routes: Routes = [{
    path: 'fr', canActivate: [localizationGuard], data: { title: 'Accueil' },
    children: [
        { path: '', component: HomepageComponent, canActivate: [localizationGuard], },
        { path: 'importantnotes', component: ImportantnotesComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Remarques importantes' }, },
        { path: 'step1', component: Step1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Détails du lieu de travail - Etape 1' }, },
        { path: 'step2', component: Step2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Détails sur le milieu de travail - Etape 2' }, },
        { path: 'step3', component: Step3Component, canActivate: [authGuard, localizationGuard], data: { title: 'Activités sur le lieu de travail - Etape 3' }, },
        { path: 'profile', component: ProfileComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Profil' }, },
        {
            path: 'safetyboard', canActivate: [authGuard, localizationGuard], data: { title: 'Commission de la santé et de la sécurité au travail' },
            children: [
                { path: '', component: SafetyboardComponent, canActivate: [authGuard, localizationGuard], },
                { path: 'healthandsafetypledge', component: HealthandsafetypledgeComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Politique en matière de santé et de sécurité' }, },
                { path: 'violenceandharassment', component: ViolenceandharassmentComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Politiques concernant la violence et le harcèlement au travail' }, },
                { path: 'ohsa', component: OccupationalHealthAndSafetyActPostComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Loi sur la santé et la sécurité au travail (LSST)' }, },
                { path: 'prevention', component: OHSComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Affiche Santé et sécurité au travail : La prévention commence ici' }, },
                { path: 'wsib', component: WSIBComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Affiche En cas de lésion au travail (« 1234 »)' }, },
                { path: 'esp', component: EmploymentStandardsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Affiche sur les normes d’emploi en Ontario' }, },
                { path: 'jhsc', component: JHSCComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Membres du comité mixte sur la santé et la sécurité au travail (CMSST)' }, },
            ]
        },
        { path: 'injuryreporting', component: InjuryreportingComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Signaler un incident ou une maladie' }, },
        { path: 'healthandsafetypartners', component: HealthAndSafetyPartnersComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Partenaires en santé et sécurité' }, },
        { path: 'mentalhealth', component: MentalhealthComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Ressources en matière de santé mentale et de soutien' }, },
        { path: 'additionalsupport', component: AdditionalsupportComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Soutien supplémentaire' }, },
        { path: 'eventsandwebinars', component: EventsandwebinarsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'FR Events and Webinars' }, },
        { path: 'legalrequirements', component: LegalrequirementsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Exigences prévues par la loi' }, },
        { path: 'training', component: TrainingComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Ressources de formation et de sensibilisation' }, },
        { path: 'accountsettings', component: AccountSettingsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Paramètres des comptes' }, },
        { path: 'reset', component: ResetComponent, canActivate: [authGuard, localizationGuard, resetGuard], data: { title: 'Reset Data' }, },
        {
            path: 'safetyplans', canActivate: [authGuard, localizationGuard], data: { title: 'Plans de sécurité' },
            children: [
                { path: '', component: SafetyplansComponent, canActivate: [authGuard, localizationGuard], },
                { path: 'newplanstep1/:id', component: Newplanstep1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Plan de sécurité – Informations de base' }, },
                { path: 'newplanstep1', component: Newplanstep1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Plan de sécurité – Informations de base' }, },
                { path: 'newplanstep2', component: Newplanstep2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Plans de sécurité - Ajouter des modules sur les risques de danger' }, },
                { path: 'newplanstep2/:id', component: Newplanstep2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Plans de sécurité - Ajouter des modules sur les risques de danger' }, },
                { path: 'newplanstep3/:id', component: Newplanstep3Component, canActivate: [authGuard, localizationGuard], data: { title: 'Plans de sécurité - Communication et formation' }, },
                { path: 'newplanstep4/:id', component: Newplanstep4Component, canActivate: [authGuard, localizationGuard], data: { title: 'Plans de sécurité - Évaluation du plan et participation des travailleurs' }, },
                { path: 'view/:safetyPlanId', component: ViewplanComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Plan de sécurité' }, },
            ]
        },
        {
            path: 'hazards', canActivate: [authGuard, localizationGuard], data: { title: 'Dangers' },
            children: [
                { path: '', component: HazardsComponent, canActivate: [authGuard, localizationGuard], },
                { path: 'customHazard', component: HazardCustomComponent, canActivate: [authGuard, localizationGuard], data: { title: "Créer une fiche d'information sur un danger propre à votre lieu de travail" }, },
                { path: 'view/:id', component: HazardlandingComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Flux de danger' }, },
                { path: 'summary/:id', component: HazardsummaryComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Flux de danger' }, },
                { path: '1/:id', component: HazardStep1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Flux de danger' }, },
                { path: '2/:id', component: HazardStep2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Flux de danger' }, },
            ]
        },
    ],
},
{
    path: '', canActivate: [localizationGuard], data: { title: 'Home' },
    children: [
        { path: '', component: HomepageComponent, canActivate: [localizationGuard], },
        { path: 'importantnotes', component: ImportantnotesComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Important Notes' }, },
        { path: 'step1', component: Step1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Workplace Details - Step 1' }, },
        { path: 'step2', component: Step2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Workplace Details - Step 2' }, },
        { path: 'step3', component: Step3Component, canActivate: [authGuard, localizationGuard], data: { title: 'Workplace Activities - Step 3' }, },
        { path: 'profile', component: ProfileComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Profile' }, },
        {
            path: 'safetyboard', canActivate: [authGuard, localizationGuard], data: { title: 'Health & Safety Board' },
            children: [
                { path: '', component: SafetyboardComponent, canActivate: [authGuard, localizationGuard], },
                { path: 'healthandsafetypledge', component: HealthandsafetypledgeComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Health & Safety Policy' }, },
                { path: 'violenceandharassment', component: ViolenceandharassmentComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Workplace Violence and Workplace Harassment Policies' }, },
                { path: 'ohsa', component: OccupationalHealthAndSafetyActPostComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Occupational Health & Safety Act (OHSA)' }, },
                { path: 'prevention', component: OHSComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Health & Safety at Work: Prevention Starts Here Poster' }, },
                { path: 'wsib', component: WSIBComponent, canActivate: [authGuard, localizationGuard], data: { title: 'In Case of Injury Poster ("1234")' }, },
                { path: 'esp', component: EmploymentStandardsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Employment Standards in Ontario Poster' }, },
                { path: 'jhsc', component: JHSCComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Joint Health and Safety Committee (JHSC) Members' }, },
            ]
        },
        { path: 'injuryreporting', component: InjuryreportingComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Report an Incident or Illness' }, },
        { path: 'healthandsafetypartners', component: HealthAndSafetyPartnersComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Health and Safety Partners' }, },
        { path: 'mentalhealth', component: MentalhealthComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Mental Health and Support Resources' }, },
        { path: 'additionalsupport', component: AdditionalsupportComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Additional Support' }, },
        { path: 'eventsandwebinars', component: EventsandwebinarsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Events and Webinars' }, },
        { path: 'legalrequirements', component: LegalrequirementsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Legal Requirements' }, },
        { path: 'training', component: TrainingComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Training and Awareness Resources' }, },
        { path: 'accountsettings', component: AccountSettingsComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Account Settings' }, },
        { path: 'reset', component: ResetComponent, canActivate: [authGuard, localizationGuard, resetGuard], data: { title: 'Reset Data' }, },
        {
            path: 'safetyplans', canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plans' },
            children: [
                { path: '', component: SafetyplansComponent, canActivate: [authGuard, localizationGuard], },
                { path: 'newplanstep1/:id', component: Newplanstep1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plan - Basic Information' }, },
                { path: 'newplanstep1', component: Newplanstep1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plan - Basic Information' }, },
                { path: 'newplanstep2', component: Newplanstep2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plan - Add Hazard Modules' }, },
                { path: 'newplanstep2/:id', component: Newplanstep2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plan - Add Hazard Modules' }, },
                { path: 'newplanstep3/:id', component: Newplanstep3Component, canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plan - Communication and Training' }, },
                { path: 'newplanstep4/:id', component: Newplanstep4Component, canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plan - Plan Evaluation and Worker Engagement' }, },
                { path: 'view/:safetyPlanId', component: ViewplanComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Safety Plan' }, },
            ]
        },
        {
            path: 'hazards', canActivate: [authGuard, localizationGuard], data: { title: 'Hazards' },
            children: [
                { path: '', component: HazardsComponent, canActivate: [authGuard, localizationGuard], },
                { path: 'customHazard', component: HazardCustomComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Create Custom Hazard' }, },
                { path: 'view/:id', component: HazardlandingComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Hazard Flow' }, },
                { path: 'summary/:id', component: HazardsummaryComponent, canActivate: [authGuard, localizationGuard], data: { title: 'Hazard Flow' }, },
                { path: '1/:id', component: HazardStep1Component, canActivate: [authGuard, localizationGuard], data: { title: 'Hazard Flow' }, },
                { path: '2/:id', component: HazardStep2Component, canActivate: [authGuard, localizationGuard], data: { title: 'Hazard Flow' }, },
            ]
        },
    ],
},
{
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
}];
