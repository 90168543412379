import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'src/app/services/business/business.service';
import { HomeDTOModel } from 'src/app/models/homeDTO.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-homepage',
  standalone: true,
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  imports: [CommonModule, TranslateModule]
})
export class HomepageComponent implements OnInit {
  private destroy$ = new Subject<void>();
  isWSIBClient: string = "No";
  showProfileCompletedAlert: boolean = false;
  totalHazards: number = 0;
  completedHazards: number = 0;
  totalSafetyPlans: number = 0;
  lastUpdatedOn: Date | undefined;
  activitiesUpdated: Date | undefined;
  isLoggedIn: boolean = false;

  constructor(public authService: AuthService, public translate: TranslateService, public router: Router, public activatedRoute: ActivatedRoute, private businessService: BusinessService) { }
  ngOnInit(): void {
    if (this.businessService.showProfileCompletedModal) {
      this.showProfileCompletedAlert = true;
      this.businessService.showProfileCompletedModal = false; 
    }
    this.authService.loggedIn$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(d => {
      if(d === true && (this.router.url == '/' || this.router.url == '/fr' || this.router.url.indexOf("?state") != -1)) {
        this.isLoggedIn = true;
        this.businessService.getHomeDTO().pipe(
          takeUntil(this.destroy$)
        ).subscribe((homeDTO: HomeDTOModel) => {
          if (homeDTO != null) {
            this.completedHazards = homeDTO.completedModules
            this.totalHazards = homeDTO.totalModules
            this.totalSafetyPlans = homeDTO.totalSafetyPlans
            this.isWSIBClient = homeDTO.isWSIBClient
            this.lastUpdatedOn = homeDTO.lastUpdatedOn
          }
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  login() {
    this.authService.login(this.router.url, this.translate.currentLang)
  }

  redirectToSignUp() {
    let lang = this.translate.currentLang == 'fr' ? 'fr' : 'en';
    const redirectUrl = "https://signin.ontario.ca/signin/register" + "/?lang=" + lang;
    window.location.href = redirectUrl;
  }

  navigateTo(location: string) {
    this.router.navigate([location])
  }

}
