import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/services/business/business.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { ActivityModel } from 'src/app/models/activity.model';
import { ActivityService } from 'src/app/services/activity-service/activity.service';

declare function scrollToError(): void

@Component({
  selector: 'app-step3',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component {
  constructor(public translate: TranslateService, 
    private router: Router, 
    private onboardingService: OnboardingService, 
    private businessService: BusinessService,
    private activityService: ActivityService,
    private changeDetector : ChangeDetectorRef) { }

  selectedOptions: string[] = [];
  activities: ActivityModel[] = [];
  editing: boolean = false;
  @ViewChild('errorHeading', { read: ElementRef }) errorHeading! : ElementRef;
    
  /* toggleExpander(expandableItem: any) {
    let item = expandableItem.currentTarget;
    item.parentNode.classList.toggle("ontario-expander--active");
    var content = item.parentNode.querySelector(
      "[data-toggle='ontario-expander-content']"
    );
    content.classList.toggle("ontario-expander__content--opened");
    content.classList.contains("ontario-expander__content--opened")
      ? content.setAttribute("aria-hidden", "false")
      : content.setAttribute("aria-hidden", "true");
    item.parentNode.classList.contains("ontario-expander--active")
      ? item.setAttribute("aria-expanded", "true")
      : item.setAttribute("aria-expanded", "false");
  } */

  ngOnInit() {
    this.loadActivities();
  }

  /* setExpandableItems() {
    var expandableItems = Array.prototype.slice.call(
      document.querySelectorAll("[data-toggle='ontario-collapse']")
    );
  } */

  loadActivities() {
    this.businessService.getBusinessActivities().subscribe(data => {
      for(var i = 0; i < data.length; i++){
        this.selectedOptions.push(data[i].activity.name);
      }
      this.activityService.getActivities().subscribe(data => {
        this.activities = data.slice(0,17);
        //this.setExpandableItems();
      });
      this.editing = this.selectedOptions.length > 0;
    });
  }

  validationFailed: boolean = false

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.focus();
    }
  }

  validateCheckboxes() {
    this.validationFailed = this.selectedOptions.length === 0;
  }

  saveAndContinue() {
    this.validateCheckboxes();

    if (this.validationFailed) {
      //scrollToError();
      this.changeDetector.detectChanges();
      this.errorHeading?.nativeElement?.focus();
      this.errorHeading?.nativeElement?.scrollIntoView();

    } else {
      this.onboardingService.updateBusinessStep3(this.selectedOptions).subscribe({
        next: () => {
          this.validationFailed = false;
          if(this.editing) {
            this.router.navigate([this.translate.currentLang == 'en' ? 'profile' : 'fr/profile']);
          } else {
            this.businessService.showProfileCompletedModal = true;
            this.router.navigate([this.translate.currentLang == 'en' ? '' : 'fr']);
          }
        },
        error: (error) => {          
        }
      })
    }
  }

  onCheckboxChange(optionValue: string) {
    if (this.selectedOptions.includes(optionValue)) {
      this.selectedOptions = this.selectedOptions.filter(val => val !== optionValue);
    } else {
      this.selectedOptions.push(optionValue);
    }
  }

  back() {
    this.router.navigate([this.translate.currentLang == 'en' ? 'step2' : 'fr/step2'])
  }

  isChecked(activity: string) : boolean {
    return this.selectedOptions.includes(activity);
  }
}