import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(private httpClient: HttpClient) { }

  addAnswers(sectionId: string, businessModuleId: string, questionAnswer: {answerText: string, questionId: number, answerOrder:number}[]): Observable<number> {
    return this.httpClient.post<number>(`${environment.apiBaseUrl}Answer/addAnswer`, {sectionID: sectionId, businessModuleID: businessModuleId,  questionAnswer: questionAnswer});
  }

  getAnswers(sectionId: string, businessModuleId: string): Observable<{answerText: string, questionId: number, answerOrder:number}[]> {
    return this.httpClient.get<{answerText: string, questionId: number, answerOrder:number}[]>(`${environment.apiBaseUrl}Answer/getAnswer?sectionID=${sectionId}&businessModuleID=${businessModuleId}`);
  }
}
