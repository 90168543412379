import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-delete-starting-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule],
  templateUrl: './delete-starting-dialog.component.html',
  styleUrls: ['./delete-starting-dialog.component.scss']
})
export class DeleteStartingDialogComponent {

  constructor(public currentDialogRef: MatDialogRef<DeleteStartingDialogComponent>, public dialog: MatDialog, public translate: TranslateService, public router: Router) { }

  onCloseClick(): void {
    this.currentDialogRef.close('Cancel');
  }

  deletePlan() {
    this.currentDialogRef.close('Delete');
  }
}
