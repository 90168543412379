import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BusinessModel, Step1Model, Step2Model } from 'src/app/models/models';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/services/business/business.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  business: BusinessModel = {
    Step1: {} as Step1Model,
    Step2: {} as Step2Model
  } as BusinessModel;

  constructor(
    public translate: TranslateService,
    public router: Router,
    private businessService: BusinessService) { }

  ngOnInit() {
    this.loadBusiness();
  }

  loadBusiness() {
    this.businessService.getBusiness().subscribe(data => {
      this.business.Activities = data.activities;
      this.business.ActivitiesFR = data.activitiesFR;
      this.business.PledgeManagementPerson = data.pledgeManagementPerson;
      this.business.PledgeManagementRole = data.PledgeManagementRole;
      this.business.Step1.BusinessName = data.step1.businessName;
      this.business.Step1.BusinessSector = (data.step1.businessSector === 'Other (please specify)') ? data.step1.otherSector : data.step1.businessSector;
      this.business.Step1.ForeignWorkers = data.step1.foreignWorkers;
      this.business.Step1.IsWSIBClient = data.step1.isWSIBClient.replace(/\s/g, '');
      this.business.Step1.NumOfWorkers = data.step1.numOfWorkers;
      this.business.Step1.PartTimeWorkers = data.step1.partTimeWorkers;
      this.business.Step1.Under25 = data.step1.under25;
      this.business.Step1.HasDesignatedSubstances = data.step1.hasDesignatedSubstances.replace(/\s/g, '');
      this.business.Step1.DesignatedSubstances = [this.designatedSubstances(data.step1.designatedSubstances)];
      this.business.Step2.MultipleLocations = data.step2.multipleLocations;
      this.business.Step2.Regions = this.regions(data.step2.regions);
      this.business.Step2.HeadOfficeLocation = data.step2.headOfficeLocation;
    });
  }

  designatedSubstances(value: string[]) {
    let returnValue = "";
    value.forEach(element => {
      returnValue += this.translate.instant("Step1."+element.replace(/\s/g, '')) + ", ";
    });
    return returnValue.slice(0, returnValue.length - 2);
  }

  regions(value: string) {
    if (value == '') return "";
    let regions = value.split(',');
    let returnValue = "";
    regions.forEach(element => {
      returnValue += this.translate.instant("Step2."+element) + ", ";
    });
    return returnValue.slice(0, returnValue.length - 2);
  }

  getYesNoValue(value: boolean) {
    if(value){
      return this.translate.instant("HardcodedGlobals.Yes")
    }
    return this.translate.instant("HardcodedGlobals.No")
  }

  edit(stepToEdit: string) {
    var link = this.translate.currentLang === 'en' ? `/${stepToEdit}` : `/fr/${stepToEdit}`;
    this.router.navigate([link])
  }
}
