import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetService {

  constructor(private httpClient: HttpClient) { }

  deleteAnswers(businessModuleId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteAnswers`, {params: {businessModuleId: businessModuleId}});
  }

  deleteHazardsCurrentBusiness(): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteHazardsCurrentBusiness`);
  }

  deleteHazard(moduleId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteHazard`, {params: {moduleId: moduleId}});
  }

  deleteHazards(businessId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteHazards`, {params: {businessId: businessId}});
  }

  deleteSectionsStatus(businessModuleId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteSectionsStatus`, {params: {businessModuleId: businessModuleId}});
  }

  deleteSafetyPlansCurrentBusiness(): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteSafetyPlansCurrentBusiness`);
  }

  deleteCurrentBusiness(): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteCurrentBusiness`);
  }

  deleteBusiness(businessId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseUrl}Reset/deleteBusiness`, {params: {businessId: businessId}});
  }
}
