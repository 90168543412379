<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{'LegalRequirements.Heading' | translate}}
          </h1>
          <p class="ontario-lead-statement">{{'LegalRequirements.Summary' | translate}}</p>          
          <br /><div  class="link"><p innerHtml="{{'LegalRequirements.Link1' | translate}}"></p> </div>
          <br /><div  class="link"><p innerHtml="{{'LegalRequirements.Link2' | translate}}"></p> </div>
          <br /><div  class="link"><p innerHtml="{{'LegalRequirements.Link3' | translate}}"></p> </div>
          <br /><div  class="link"><p innerHtml="{{'LegalRequirements.Link4' | translate}}"></p> </div>
          <br /><h2 class="title">
            {{'LegalRequirements.TitleSection' | translate}}
          </h2>
          <p class="ontario-lead-statement">{{'LegalRequirements.TitleSummary' | translate}}</p><br />          
        </div>
    </div>
    <div class="ontario-row">
      <div class="ontario-columns top-banner">
          <div class="ontario-table-container ontario-small-12 ontario-medium-12 ontario-large-12">
              <div class="ontario-table-div">
                  <table class="legal-table" tabindex="0">
                      <thead>
                          <tr>
                              <th scope="col">
                                {{'LegalRequirements.TableHeaderCitation' | translate}}
                              </th>
                              <th scope="col">
                                {{'LegalRequirements.TableHeaderTitle' | translate}} 
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation1' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title1' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation2' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title2' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation3' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title3' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation4' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title4' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation5' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title5' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation6' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title6' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation7' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title7' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation8' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title8' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation9' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title9' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation10' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title10' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation11' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title11' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation12' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title12' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation13' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title13' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation14' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title14' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation15' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title15' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation16' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title16' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation17' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title17' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation18' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title18' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation19' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title19' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation20' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title20' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation21' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title21' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation22' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title22' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation23' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title23' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation24' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title24' | translate}}"></p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {{'LegalRequirements.Citation25' | translate}} 
                          </th>
                          <td>
                            <p innerHtml="{{'LegalRequirements.Title25' | translate}}"></p>
                          </td>
                        </tr>
                      </tbody>
                  </table>
              </div>
          </div>          
      </div>
  </div>
</div>
   