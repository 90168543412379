import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';

@Component({
  selector: 'app-injuryreporting',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './injuryreporting.component.html',
  styleUrls: ['./injuryreporting.component.scss']
})
export class InjuryreportingComponent {
  constructor(public authService: AuthService, public translate: TranslateService,private onboardingService: OnboardingService, public router: Router ) { }

  IsWSIBClient: string | null = null;
  
  ngOnInit() {
    this.onboardingService.getStep1Answers().subscribe(
      (initialAnswers) => {
        if (initialAnswers != null) {
          this.IsWSIBClient = initialAnswers.isWSIBClient;
        }
      });
  }
}


