import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-healthandsafetypartners',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './healthandsafetypartners.component.html',
  styleUrls: ['./healthandsafetypartners.component.scss']
})
export class HealthAndSafetyPartnersComponent {
  constructor(private translate: TranslateService) {}

  toggleExpander(expandableItem: any) {
    let item = expandableItem.currentTarget;
    item.parentNode.classList.toggle("ontario-expander--active");
    var content = item.parentNode.querySelector(
      "[data-toggle='ontario-expander-content']"
    );
    content.classList.toggle("ontario-expander__content--opened");
    content.classList.contains("ontario-expander__content--opened")
      ? content.setAttribute("aria-hidden", "false")
      : content.setAttribute("aria-hidden", "true");
    item.parentNode.classList.contains("ontario-expander--active")
      ? item.setAttribute("aria-expanded", "true")
      : item.setAttribute("aria-expanded", "false");
  }  
}
