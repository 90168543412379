import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig, MatDialogModule } from "@angular/material/dialog";
import { WorkplaceAddUserDialogComponent } from '../workspace-add-user-dialog/workspace-add-user-dialog.component';
import { BusinessService } from '../../services/business/business.service';
import { WorkspaceRemoveConfirmDialogComponent } from '../workspace-remove-confirm-dialog/workspace-remove-confirm-dialog.component';
import { UserInfoService } from 'src/app/services/user-info/user-info.service';
import { EmailPreferencesDialogComponent } from '../email-preferences-dialog/email-preferences-dialog.component';

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, WorkspaceRemoveConfirmDialogComponent],
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent {
  constructor(public dialog: MatDialog, public translate: TranslateService, private businessService: BusinessService, private userInfoService: UserInfoService) {
    this.userInfoService.getUserInfo().subscribe(data => {
      this.currentUser = JSON.parse(data.value);
    })
  }

  currentUser : any = {};
  headingFocused: boolean = false;
  showSuccessAlert: boolean = false;
  showAddedUser: boolean = false;
  showRemoveUser: boolean = false;
  showEmailPreferenceUpdated: boolean = false;
  users : {hasLoggedIn: boolean, userEmail: string}[] = [];
  private focusableElements: any;

  ngOnInit(): void {
    this.businessService.getBusinessUsers().subscribe(data => {
      this.users = data;
      let index = this.users.findIndex(u => u.userEmail == this.currentUser.Email);
      if (index > 0){
        let temp = this.users[index];
        this.users[index] = this.users[0];
        this.users[0] = temp;
      }
    })
  }

  ngAfterViewChecked() {
    if (this.showSuccessAlert && !this.headingFocused) {
      const calloutHeading= document.getElementsByClassName("ontario-alert__body");
      if (calloutHeading.length > 0) {
        const headingElement = calloutHeading[0] as HTMLElement;
        this.headingFocused = true;
        headingElement.setAttribute("tabindex", "-1");
        headingElement.focus();
      }
    }
  }

  openEmailPreferenceDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '350px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.data =
    {
      isPeriodicEmailForPlanEffectivenesUpdateEnabled: this.currentUser.IsPeriodicEmailForPlanEffectivenesUpdateEnabled,
      isEmailCommunicationAboutLegislationChangesEnabled: this.currentUser.IsEmailCommunicationAboutLegislationChangesEnabled,
      isUserResearchParticipationEnabled: this.currentUser.IsUserResearchParticipationEnabled
    };
    this.setTabIndex();
    const dialogRef = this.dialog.open(EmailPreferencesDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((emailPreferences: {
      isPeriodicEmailForPlanEffectivenesUpdateEnabled: boolean, 
      isEmailCommunicationAboutLegislationChangesEnabled: boolean,
      isUserResearchParticipationEnabled: boolean
    }) => 
    {
      this.removeTabIndex();
      if (
          emailPreferences != undefined &&
          (this.currentUser.IsPeriodicEmailForPlanEffectivenesUpdateEnabled != emailPreferences.isPeriodicEmailForPlanEffectivenesUpdateEnabled ||
          this.currentUser.IsEmailCommunicationAboutLegislationChangesEnabled != emailPreferences.isEmailCommunicationAboutLegislationChangesEnabled||
          this.currentUser.IsUserResearchParticipationEnabled != emailPreferences.isUserResearchParticipationEnabled)
        )
      {
        this.currentUser.IsPeriodicEmailForPlanEffectivenesUpdateEnabled = emailPreferences.isPeriodicEmailForPlanEffectivenesUpdateEnabled;
        this.currentUser.IsEmailCommunicationAboutLegislationChangesEnabled = emailPreferences.isEmailCommunicationAboutLegislationChangesEnabled;
        this.currentUser.IsUserResearchParticipationEnabled = emailPreferences.isUserResearchParticipationEnabled;
        this.currentUser.LastUpdatedBy = "";
        try{
          this.userInfoService.updateUserInformation(this.currentUser).subscribe(_ => {
          });
          this.resetFlags();
          this.showEmailPreferenceUpdated = true;
          this.showSuccessAlert = true;
          window.scrollTo(0, 0);
        }
        catch(err){
          console.error(err);
          this.resetFlags();
        }
      }
    });
  }
  
  openAddDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = this.users;
    this.resetFlags();
    
    this.setTabIndex();
    const dialogRef = this.dialog.open(WorkplaceAddUserDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe((addedUser: boolean) => {
      this.removeTabIndex();
      if (addedUser) {
        this.ngOnInit();
        this.resetFlags();
        this.showAddedUser = true;
        this.showSuccessAlert = true;
        window.scrollTo(0, 0);
      }
    });
  }
    
  openRemoveDialog(index : number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = {
      email: this.users[index].userEmail,
      currentUser: this.users[index].userEmail == this.currentUser.Email
    }
    if (this.users.length > 1) {
      this.setTabIndex();
      const dialogRef = this.dialog.open(WorkspaceRemoveConfirmDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        this.removeTabIndex();
        if (result === 'Remove') {
          this.ngOnInit();
          this.resetFlags();
          this.showRemoveUser = true;
          this.showSuccessAlert = true;
          window.scrollTo(0, 0);
        }
        else {
          this.resetFlags();
        }
      });
    }
  }

  shouldShowRemoveButton(){
    return this.users.filter(u => u.hasLoggedIn).length > 1;
  }

  private setTabIndex() {
    if (!this.focusableElements) {
      this.focusableElements = Array.from(
        document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
      ).filter(el => !document.querySelector('.modal')?.contains(el));
    }
    this.focusableElements.forEach((el: any) => {
      const currentTabindex = el.getAttribute('tabindex');
      if (currentTabindex != null)
        el.setAttribute("tabindexPlaceHolder", currentTabindex);
      el.setAttribute('tabindex', '-1');
    });
  }

  private removeTabIndex() {
    this.focusableElements.forEach((el: any) => {
      const tabindexPlaceHolder = el.getAttribute('tabindexPlaceHolder');
      if (tabindexPlaceHolder != null)  {
        el.setAttribute('tabindex', tabindexPlaceHolder);
        el.removeAttribute('tabindexPlaceHolder');
      }
      else 
        el.removeAttribute('tabindex');
    });
  }

  resetFlags(){
    this.showSuccessAlert = false;
    this.showAddedUser = false;
    this.showRemoveUser = false;
    this.showEmailPreferenceUpdated = false;
    this.headingFocused = false;
  }
}
