<footer class='ontario-footer ontario-footer--expanded'>
    <div class='ontario-footer__expanded-top-section'>
        <div class='ontario-row'>
            <div class='ontario-columns ontario-small-12 ontario-medium-6'>
                <h2 class='ontario-h4'>{{'General.MinistryOfLabourTrainingAndSkillsDevelopment' | translate}}</h2>
                <p>{{'General.TheMinistryOfLabourTrainingAndSkillsDevelopmentAdvancesSafe' | translate}}</p>
            </div>
            <div class='ontario-columns ontario-small-12 ontario-medium-6'>
                <h2 class='ontario-h4'>{{'General.ContactUs' | translate}}</h2>
                <p><a href='mailto:workplacesafetyplanbuilder@ontario.ca'>{{'General.SendUsAnEmail' | translate}}</a>.</p>
            </div>
        </div>
    </div>
    <div class='ontario-row ontario-footer__expanded-bottom-section'>
        <div class='ontario-columns ontario-small-12'>
            <ul class='ontario-footer__links-container ontario-footer__links-container--inline'>
                <li><a class='ontario-footer__link' target="_blank" href="{{'General.AccessibilityLink' | translate}}" >{{'General.Accessibility' | translate}}</a></li>
                <li><a class='ontario-footer__link' target="_blank" href="{{'General.PrivacyLink' | translate}}" >{{'General.Privacy' | translate}}</a></li>
                <li><a class='ontario-footer__link' href="mailto:workplacesafetyplanbuilder@ontario.ca" >{{'General.ContactUs' | translate}}</a>
                <li><a class='ontario-footer__link' target="_blank" href="{{'General.TermsOfUseLink' | translate}}" >{{'General.TermsOfUse' | translate}}</a>
            </ul>
            <div class='ontario-footer__copyright'>
                <a class='ontario-footer__link' target="_blank" href="{{'General.KingsPrinterLink' | translate}}" >{{'General.KingsPrinter' | translate}}</a>
            </div>
        </div>
    </div>
</footer>

<script src="assets/ontario-design-system/scripts/svgxuse.js"></script>
<script>
  //disable clicks in iframe to prevent cors issues
  if (window.frameElement) {
    var footerLinks = Object.values(document.getElementsByClassName('ontario-footer__link'));
    if (footerLinks) {
      footerLinks.forEach(function (item) {
        item.addEventListener('click', function (event) {
          event.preventDefault()
        })
      })
    }
  }
</script>