<div class="ontario-padding-bottom-48-!">
  <div class="ontario-row">
    <div class="ontario-columns top-banner">
      <h1 class="title">
        {{'Injuryreporting.InjuryReporting' | translate}}
      </h1>          
      <p innerHTML="{{'Injuryreporting.Introduction' | translate}}"></p> 
      <div  class="link"><p innerHtml="{{'Injuryreporting.IntroductionLink' | translate}}"></p> 
        <!-- <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" ><use href="#ontario-icon-new-window"></use></svg> -->
      </div>
      <div class="link"><p innerHtml="{{'Injuryreporting.SubmitReportLink' | translate}}"></p></div>
    </div>
  </div>
  <br/>
  <div class="ontario-row" *ngIf="this.IsWSIBClient === 'Yes'">
    <div class="ontario-columns">
      <h2 class="title">
        {{'Injuryreporting.WSIBHeading' | translate}}
      </h2>          
      <p innerHTML="{{'Injuryreporting.WSIBIntroduction' | translate}}"></p><br />
      <div class="link"><p innerHtml="{{'Injuryreporting.ReportWSIBLink' | translate}}"></p></div>
    </div>
  </div>
</div>