import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ohs',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './ohs.component.html',
  styleUrls: ['./ohs.component.scss']
})
export class OHSComponent {
  EnPage:boolean = this.translate.currentLang == 'en' ;
  FrPage:boolean = this.translate.currentLang != 'en' ;
  constructor(private translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute) { }

}
