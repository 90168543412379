<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h1 class="title">
                {{'Training.Heading' | translate}}
            </h1> 
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-lead-statement">{{'Training.Summary' | translate}}</div><br />
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
                  
          <div class="link"><p innerHtml="{{'Training.Link1' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link2' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link3' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link4' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link5' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link6' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link7' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link8' | translate}}"></p> </div>
          <br /><div class="link"><p innerHtml="{{'Training.Link9' | translate}}"></p> </div>
        </div>
    </div>
</div>
   