import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserInfoService } from 'src/app/services/user-info/user-info.service';
import { Meta } from '@angular/platform-browser';
import { PdfgeneratorService } from 'src/app/services/pdfgenerator/pdfgenerator.service';
import { Clipboard } from "@angular/cdk/clipboard";
import { SafetyPlanService } from 'src/app/services/safetyplan/safetyplan.service';
import { filter } from 'rxjs';
import { AnswerModel, BusinessModuleModel, ControlMeasureModel, SafetyPlanModel } from 'src/app/models/models';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { ControlMeasureService } from 'src/app/services/control-measure/control-measure.service';

@Component({
  selector: 'app-viewplan',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './viewplan.component.html',
  styleUrls: ['./viewplan.component.scss']
})
export class ViewplanComponent {
  isOwner: boolean = false;
  showCopyAlert: boolean = false;
  guid: any
  plan: SafetyPlanModel | undefined;
  businessModules: BusinessModuleModel[] = [];
  currentLanguage: string = '';
  planEvaluationInterval: string = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public translate: TranslateService, public authService: AuthService, public userInfoService: UserInfoService, private meta: Meta, public pdfGenerator: PdfgeneratorService, private clipboard: Clipboard, private safetyPlanService: SafetyPlanService, private businessModuleService: BusinessModuleService, private controlMeasureService: ControlMeasureService) {
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    this.guid = this.activatedRoute.snapshot.paramMap.get('safetyPlanId');
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const urlParts = this.router.url.split('/');
        this.currentLanguage = urlParts[1];
      });
  }

  ngOnInit() {  
    if (this.authService.loggedInStatus.value === true) {
      this.userInfoService.isPlanOwner(this.guid).subscribe((result: any) => {
        if (result === true) {
          this.isOwner = true;
        }
      })
    } else {
      this.isOwner = false;
    }
    this.safetyPlanService.getSafetyPlan(this.guid).subscribe((plan: SafetyPlanModel) => {
      this.plan = plan;
      this.planEvaluationInterval = plan.whenIsPlanReviewed === "other" 
        ? plan.planEvaluationOtherInterval
        : this.translate.instant("NewPlanStep4." + this.plan.whenIsPlanReviewed)
      plan.safetyPlanBusinessModules.forEach((x: any) => {
        this.businessModuleService.getBusinessModule(x.businessModuleId).subscribe(bm => {
          this.businessModules.push(bm);
        });
      });
    })
  }

  filterAnswers(answers: AnswerModel[], questionNum: number, sectionNum: number) {
    return answers.filter(a => a.questionId == questionNum && a.sectionNumber == sectionNum);
  }

  initiateEdit() {
    this.router.navigate(['../../newplanstep1', this.guid], { relativeTo: this.activatedRoute })
  }

  copyLink() {
    let lang = this.translate.currentLang == 'fr' ? '/fr' : ''
    let link = 'https://' + window.location.host + lang + '/safetyplans/view/' + this.guid
    this.clipboard.copy(link)
    this.showCopyAlert = true
  }
}