import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-employment-standards',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './employment-standards.component.html',
  styleUrls: ['./employment-standards.component.scss']
})
export class EmploymentStandardsComponent {
  EnPage: boolean = this.translate.currentLang == 'en' ;
  FrPage: boolean = this.translate.currentLang != 'en' ;
  constructor(private translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute) { }
}
