import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdleTimeoutService } from 'src/app/services/idle-timeout/idle-timeout.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatDialog, MatDialogConfig, MatDialogModule } from "@angular/material/dialog";
import { ConfirmationCloseDialogComponent } from '../confirmation-close-dialog/confirmation-close-dialog.component';
import { UserInfoService } from 'src/app/services/user-info/user-info.service';

@Component({
  selector: 'app-session-timeout',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent {
  dialogRef: any
  minutesToExpire: number | undefined
  constructor(public dialog: MatDialog, public changeRef: ChangeDetectorRef, public idleTimeoutService: IdleTimeoutService, public authService: AuthService, public userInfoService: UserInfoService) { }

  ngOnInit(): void {
    this.idleTimeoutService.oneMinutePassed.subscribe(() => {
      if (this.authService.isSessionExpiryValid()) {
        this.minutesToExpire = this.authService.sessionToExpireWithinTime();
        if (this.minutesToExpire <= 15)
        {
          this.showDialogForExtendSession(this.minutesToExpire);
        }
      }
    });
  }

  public showDialogForExtendSession(mins: number) {
    try {
      this.dialog.closeAll();
    } catch (e) {
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    dialogConfig.data =
      {
        mins: mins,
        inactive: 60 - mins
      };

    this.dialogRef = this.dialog.open(ConfirmationCloseDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe
    (
      (data: boolean) => {
        if (data === true) {
          this.idleTimeoutService.startTimer();
          this.changeRef.markForCheck();
          this.userInfoService.isAuthenticated().subscribe((data: any) => {
          });
        } else if (data === false) {
          this.changeRef.markForCheck()
          this.authService.gotoLogout()
        }
      }
    );
  }
}

