<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                <div *ngIf="validationFailed" class="ontario-alert ontario-alert--error" id="errorHeading" #errorHeading tabindex="0">
                    <div class="ontario-alert__header">
                        <div class="ontario-alert__header-icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </div>
                        <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                    </div>
                    <div class="ontario-alert__body">
                        <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                        <ul>
                            <li *ngIf="validationFailed"><a href="javascript:{}" (click)="scrollToElement('checkbox-option-0')">{{'Step3.ErrorSelectOne' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--with-back-button">
                            <button (click)="back()" class="ontario-button ontario-button--tertiary" >
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-left"></use>
                                </svg>{{'Buttons.Back' | translate}}
                            </button>
                            <span class="ontario-h4">{{'Stepper.Step' | translate}}&nbsp;3 {{'Stepper.Of' | translate}}&nbsp;3</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>  
            <h1 class="title">
                {{'Step3.Title' | translate}}
            </h1>
            <p class="ontario-lead-statement">
                {{'Step3.LeadStatement' | translate}}
            </p><br>
        </div>
    </div>

    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="Q1label">
                {{'Step3.Q1' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </legend>
            <div *ngIf="validationFailed" class="ontario-error-messaging" id="field-error">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'Step3.ErrorSelectOne' | translate}}
                </span>
            </div>
            <p id="error-messaging-input-hint-and-hint-expander-hint" class="ontario-hint">{{'HardcodedGlobals.SelectAll' | translate}}</p>
            </fieldset>
        </div>
    </div>

    <div class="row">
        <div class="container">
            <div *ngFor="let activity of activities; index as i" class="parent-item">
                <fieldset>
                    <legend></legend>
                    <div class="item">
                        <img src="{{activity.imageURL}}" alt="">
                        <div class="ontario-checkboxes__item">
                            <input class="ontario-checkboxes__input" id="checkbox-option-{{i}}" name="options" type="checkbox" value="{{activity.name}}" (change)="onCheckboxChange(activity.name)" [checked]="isChecked(activity.name)">
                            <label class="ontario-checkboxes__label" for="checkbox-option-{{i}}">
                                {{translate.currentLang === 'en' ? activity.name : activity.nameFR}}
                            </label>
                        </div>
                    </div>    
                </fieldset>
                <!-- <div *ngIf="activity.summary.length > 0" class="new-div ontario-hint-expander__container">
                    <button class="ontario-hint-expander__button" id="hint-expander-button-id-{{i}}" aria-controls="hint-expander-content" aria-expanded="false" data-toggle="ontario-collapse" (click)="toggleExpander($event)">
                        <span class="ontario-hint-expander__button-icon--close"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-chevron-up"></use>
                            </svg></span>
                        <span class="ontario-hint-expander__button-icon--open"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-chevron-down"></use>
                            </svg></span>
                        More information
                    </button>
                    <div class="ontario-hint-expander__content" id="hint-expander-content-{{i}}" aria-hidden="true" data-toggle="ontario-expander-content">
                        <p>{{translate.currentLang === 'en' ? activity.summary : activity.summaryFR}}</p>
                    </div>
                </div> -->
            </div>    
            <div *ngIf="activities.length % 3 == 2" style="width: 31%;"></div>
            <p>{{'Step3.Note' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <br />
        <button class="ontario-button ontario-button--secondary" (click)="back()">
            {{ 'Buttons.Back' | translate}}
        </button>
        <button (click)="saveAndContinue()" class="ontario-button ontario-button--primary">
            {{ 'Buttons.SaveAndContinue' | translate}}
        </button>
        </div>
    </div>
</div>
