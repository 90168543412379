import { CanActivateFn } from '@angular/router';
import { UserInfoService } from '../services/user-info/user-info.service';
import { inject } from '@angular/core';
import { map, of } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SubheaderService } from '../services/subheader/subheader.service';
import { BreadcrumbsService } from '../services/breadcrumbs/breadcrumbs.service';


export const authGuard: CanActivateFn = (route, state) => {
  const userInfoService = inject(UserInfoService)
  const authService = inject(AuthService)
  const translate = inject(TranslateService)
  const subheaderService = inject(SubheaderService)
  const breadcrumbsService = inject(BreadcrumbsService)

  return userInfoService.isAuthenticated().pipe(
    map(data => {
      let authenticated = data.value.toLowerCase() == "true"
      if (authenticated) {
        if (state.url.includes('/safetyplans/view')) {
          const parts = state.url.split('/');
          const planId = parts[parts.length - 1];
          subheaderService.updateShowSubheader(userInfoService.isPlanOwner(planId))
          breadcrumbsService.updateShowBreadcrumbs(userInfoService.isPlanOwner(planId))
          authService.setLoggedIn(true)
          return true
        }
        subheaderService.updateShowSubheader(of(true));
        breadcrumbsService.updateShowBreadcrumbs(of(true));
        return true
      }
      else {
        if (state.url.includes('/safetyplans/view')) {
          subheaderService.updateShowSubheader(of(false));
          breadcrumbsService.updateShowBreadcrumbs(of(false));
          return true
        }
        authService.login(state.url, translate.currentLang)
      }
      return false
    })
  )
};
