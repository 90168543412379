import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeleteStartingDialogComponent } from '../delete-starting-dialog/delete-starting-dialog.component';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PdfgeneratorService } from 'src/app/services/pdfgenerator/pdfgenerator.service';
import { SafetyPlanService } from "../../services/safetyplan/safetyplan.service";
import { SafetyPlanModel } from "../../models/safetyplan.model";
import { Clipboard } from "@angular/cdk/clipboard";
import { filter } from 'rxjs';

@Component({
  selector: 'app-safetyplans',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, DeleteStartingDialogComponent],
  templateUrl: './safetyplans.component.html',
  styleUrls: ['./safetyplans.component.scss']
})
export class SafetyplansComponent implements OnInit {
  showSuccessAlert = false;
  safetyPlans: SafetyPlanModel[] = []
  lastStepCompleted: number = 0;
  showCopyAlert: boolean = false;
  businessName!: string;
  copiedPlanIndex: number = -1;
  currentLanguage: string = '';

  constructor(public dialog: MatDialog, public translate: TranslateService, public safetyPlanService: SafetyPlanService, private router: Router, private activatedRoute: ActivatedRoute, public pdfGenerator: PdfgeneratorService, private clipboard: Clipboard) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const urlParts = this.router.url.split('/');
        this.currentLanguage = urlParts[1];
      });
  }

  ngOnInit(): void {
    this.refreshData();
  }

  copyLink(safetyPlanId: string, index: number) {
    let lang = this.translate.currentLang == 'fr' ? '/fr' : ''
    let link = 'https://' + window.location.host + lang + '/safetyplans/view/' + safetyPlanId
    this.clipboard.copy(link)
    this.copiedPlanIndex = index;
  }

  createNewPlan() {
    this.router.navigate(['newplanstep1'], { relativeTo: this.activatedRoute });
  }

  viewPlan(safetyPlanId: string) {
    this.router.navigate(['view', safetyPlanId], { relativeTo: this.activatedRoute })
  }

  deletePlan(planId: string) {
    const dialogRef = this.dialog.open(DeleteStartingDialogComponent, {
      data: {
        cancelLabel: 'Cancel',
        deleteLabel: 'Delete',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Delete') {
        this.safetyPlanService.deleteSafetyPlan(planId).subscribe(() => {
          this.safetyPlanService.getSafetyPlans().subscribe(data => {
            this.safetyPlans = data;
          });
          this.showSuccessAlert = true;
          window.scrollTo(0, 0);
        }, error => {
          this.showSuccessAlert = false;
        });
      } else {
        this.showSuccessAlert = false;
      }
    });
  }

  refreshData() {
    this.safetyPlanService.getSafetyPlans().subscribe(data => {
      if (data && data.length > 0) {
        this.safetyPlans = data;
        if (data[0].business && data[0].business.name) {
          this.businessName = this.safetyPlans[0].business.name;
        }
      }
    });
  }

  continueSafetyPlan(planId: string) {
    this.safetyPlanService.getPlanDetails(planId).subscribe((details: any) => {
      this.lastStepCompleted = details.lastStepCompleted;
      
      if(this.lastStepCompleted <= 3) {
        this.router.navigate([`newplanstep${this.lastStepCompleted + 1}`, planId], { relativeTo: this.activatedRoute });
      }
    });
  }

  downloadSafetyPlan(plan: SafetyPlanModel) {
    this.safetyPlanService.getSafetyPlan(plan.id).subscribe((plan: SafetyPlanModel) => {
      this.pdfGenerator.downloadSafetyPlanPDF(plan, this.currentLanguage);
    });
  }
}