import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SafetyPlanModel, SafetyPlanDTOModel } from "../../models/models";

@Injectable({
  providedIn: 'root'
})
export class SafetyPlanService {
  constructor(private httpClient: HttpClient) { }

  addNewOrUpdateStep1Plan(data: SafetyPlanDTOModel): Observable<string> {
    return this.httpClient.post<string>(environment.apiBaseUrl + 'safetyPlan/AddNewOrUpdateStep1Plan', data);
  }

  addOrUpdateSafetyPlanBusinessModules(data: any): Observable<string> {
    return this.httpClient.post<string>(environment.apiBaseUrl + 'safetyPlan/AddOrUpdateSafetyPlanBusinessModules', data);
  }

  getSafetyPlans(): Observable<SafetyPlanModel[]> {
    return this.httpClient.get<SafetyPlanModel[]>(environment.apiBaseUrl + 'safetyPlan')
  }

  deleteSafetyPlan(planId: string): Observable<any> {
    return this.httpClient.delete<any>(environment.apiBaseUrl + `safetyPlan/${planId}`)
  }

  getPlanDetails(planId: string): Observable<SafetyPlanModel> {
    return this.httpClient.get<SafetyPlanModel>(environment.apiBaseUrl + `safetyPlan/${planId}`);
  }

  getSafetyPlan(planId: string): Observable<SafetyPlanModel> {
    return this.httpClient.get<SafetyPlanModel>(environment.apiBaseUrl + `safetyPlan/full/${planId}`);
  }

  updateStep2Plan(planId: string): Observable<string> {
    return this.httpClient.post<string>(environment.apiBaseUrl + 'safetyPlan/updateStep2Plan', {}, {params: {planId: planId}});
  }

  updateStep3Plan(planId: string, options: string[], subOptions: string[], otherOption: string, otherSubOption: string): Observable<string> {
    return this.httpClient.post<string>(environment.apiBaseUrl + 'safetyPlan/updateStep3Plan', {options: options, subOptions: subOptions, otherOption: otherOption, otherSubOption: otherSubOption}, {params: {planId: planId}});
  }

  updateStep4Plan(planId: string, whenIsPlanReviewed: string, options: string[], otherOption: string, otherInterval: string): Observable<string> {
    return this.httpClient.post<string>(environment.apiBaseUrl + 'safetyPlan/updateStep4Plan', {options: options, otherOption: otherOption, whenIsPlanReviewed: whenIsPlanReviewed, otherInterval}, {params: {planId: planId}});
  }

  checkSafetyPlanBusinessModuleExists(planId: string, businessModuleId: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiBaseUrl + `safetyPlan/${planId}/${businessModuleId}`);
  }
}