import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityModel } from 'src/app/models/activity.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private httpClient: HttpClient) { }

  getActivities(): Observable<ActivityModel[]> {
    return this.httpClient.get<ActivityModel[]>(`${environment.apiBaseUrl}activity`);
  }
}
