import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public isLoadingSignal = signal<boolean>(true)

  show() {
    this.isLoadingSignal.set(true)
  }

  hide() {
    this.isLoadingSignal.set(false)
  }
}
