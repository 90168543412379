import { Component, Inject, LOCALE_ID, Input, Renderer2, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AccountComponent } from '../account/account.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoginComponent } from '../login/login.component';
import { FeedbackComponent } from "../feedback/feedback.component";
import { MatDialog } from "@angular/material/dialog";
import { FeedbackStartingDialogComponent } from '../feedback-starting-dialog/feedback-starting-dialog.component';
import { SubheaderService } from 'src/app/services/subheader/subheader.service';
import { BusinessService } from 'src/app/services/business/business.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, AccountComponent, LoginComponent, FeedbackComponent, TranslateModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  onboardingCompleted: boolean = false;
  currentUrl!: string;
  menuOpen: boolean = false;
  @Input() title: string = '';

  constructor(public dialog: MatDialog, @Inject(LOCALE_ID) public locale: string, public translate: TranslateService, public router: Router, private route: ActivatedRoute, public authService: AuthService, private renderer: Renderer2, private el: ElementRef, public subheaderService: SubheaderService, private businessService: BusinessService) {}
  
  ngOnInit() {
    this.currentUrl = window.location.href;
    if (this.currentUrl.includes('/fr/')) {
      this.toggleLanguage('fr');
    }
    else {
      this.toggleLanguage('en');
    }
  }

  ngAfterViewInit() {
    this.authService.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn === true) {
        const navigation = document.getElementById('divLinksMenu');
        if (navigation) {
          navigation.style.display = 'inherit';
        }
        this.businessService.getBusiness().subscribe(data => {
          if (data != null && data.onboardingCompleted == true) {
            this.onboardingCompleted = true;
          }
        })
      }

      if (loggedIn === false) {
        const navigation = document.getElementById('divLinksMenu');
        if (navigation) {
          navigation.style.display = 'none';
        }
      }
    });
  }

  navigateHome() {
    this.router.navigate([this.translate.currentLang == 'en' ? '/' : '/fr'])
  }

  changeLanguage(language: string) {
    let urlSegments = window.location.pathname.split('/')
    const filteredSegments = urlSegments.filter(segment => segment !== "");
    let builtUrl = ''

    filteredSegments.forEach(x => builtUrl += this.findTranslation(x, language == 'fr'))

    if (language == 'fr' && !builtUrl.includes('/fr')) builtUrl = '/fr' + builtUrl
    this.translate.use(language)
    this.router.navigate([builtUrl])
    this.toggleLanguage(language);
  }

  toggleLanguage(language: string) {
    const appElement = this.el.nativeElement.querySelector('.my-app');
    this.renderer.removeClass(appElement, 'fr');
    if (language === 'fr') {
      this.renderer.addClass(appElement, 'fr');
    }
  }

  findTranslation(path: string, isFrench: boolean): string {
    switch (path) {
      case 'fr':
        return isFrench ? '/fr' : ''
      
    }
    return "/" + path;
  }

  logout() {
    this.menuOpen = false
    this.authService.gotoLogout()
  }

  openDialog() {
    this.dialog.open(FeedbackStartingDialogComponent);
  }
}