import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { BusinessModuleModel } from 'src/app/models/models';

@Component({
  selector: 'app-hazards',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule ],
  templateUrl: './hazards.component.html',
  styleUrls: ['./hazards.component.scss']
})
export class HazardsComponent {
  constructor(private businessModuleService: BusinessModuleService, public translate: TranslateService, public router: Router, public activatedRoute: ActivatedRoute) {}
  @ViewChild('checkboxContainer') checkboxContainer!: ElementRef;
  businessModules: BusinessModuleModel[] = [];
  dismissedHazards: boolean = false;
  conditionalCallout: boolean = false;
  completed: string = "n";

  ngOnInit() {
    this.activatedRoute.queryParams
      .subscribe((params: any) => {
        this.completed = params.c;
      }
    );
    this.loadBusinessModules();
    setTimeout(() => { this.setExpandableItems(); }, 200);
  }

  toggleExpander(expanders: any) {
    expanders.forEach(function (item: any, index: any) {
      item.addEventListener("click", function () {
        item.parentNode.classList.toggle("ontario-expander--active");
        var content = item.parentNode.querySelector(
          "[data-toggle='ontario-expander-content']"
        );
        content.classList.toggle("ontario-expander__content--opened");
        content.classList.contains("ontario-expander__content--opened")
          ? content.setAttribute("aria-hidden", "false")
          : content.setAttribute("aria-hidden", "true");
        item.parentNode.classList.contains("ontario-expander--active")
          ? item.setAttribute("aria-expanded", "true")
          : item.setAttribute("aria-expanded", "false");
      });
    });
  }

  setExpandableItems() {
    var expandableItems = Array.prototype.slice.call(
      document.querySelectorAll("[data-toggle='ontario-collapse']")
    );
    this.toggleExpander(expandableItems);
  }

  loadBusinessModules() {
    this.businessModuleService.getBusinessModules('').subscribe(bm => {

      const filteredModules = bm.filter(module => module.module.priority <= 12 || module.module.priority == 500);
      
      this.businessModules = filteredModules.sort((a: any, b: any) => {
        if (a.completed !== b.completed) {
          return b.completed ? -1 : 1; 
        }
    
        if (a.completedSectionsCount !== b.completedSectionsCount) {
          return b.completedSectionsCount - a.completedSectionsCount; 
        }
    
        if (a.lastUpdatedOn !== b.lastUpdatedOn) {
          return a.lastUpdatedOn - b.lastUpdatedOn;
        }
    
        return a.module.priority - b.module.priority;
      });
      this.dismissedHazards = this.filterModules('Hazard', true)?.length > 0;
      const completedModules = filteredModules.filter(module => module.completed && !module.dismissed);
      if (completedModules.length > 0) {
        this.conditionalCallout = true;
      } else {
        this.conditionalCallout = false;
      }
    });
  }

  displayDetail(id: string) {
    this.router.navigate(['view', id], { relativeTo: this.activatedRoute});
  }

  filterModules(type: string, dismissed: boolean) {
      return this.businessModules?.filter((f: { module: any; dismissed: boolean; }) => (f.module.type === type || f.module.type === "CustomHazard") && f.dismissed === dismissed );
  }
}
