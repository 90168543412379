import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig, MatDialogModule } from "@angular/material/dialog";
import { FeedbackStartingDialogComponent } from '../feedback-starting-dialog/feedback-starting-dialog.component';


@Component({
  selector: 'app-feedback',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, FeedbackStartingDialogComponent],
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {
  constructor(public dialog: MatDialog,public authService: AuthService, public translate: TranslateService) {
  }
  private focusableElements: any;

  openDialog(){
    this.setTabIndex();
    const dialogRef = this.dialog.open(FeedbackStartingDialogComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.removeTabIndex();
    });
  }  

  private setTabIndex() {
    if (!this.focusableElements) {
      this.focusableElements = Array.from(
        document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
      ).filter(el => !document.querySelector('.modal')?.contains(el));
    }
    this.focusableElements.forEach((el: any) => {
      const currentTabindex = el.getAttribute('tabindex');
      if (currentTabindex != null)
        el.setAttribute("tabindexPlaceHolder", currentTabindex);
      el.setAttribute('tabindex', '-1');
    });
  }

  private removeTabIndex() {
    this.focusableElements.forEach((el: any) => {
      const tabindexPlaceHolder = el.getAttribute('tabindexPlaceHolder');
      if (tabindexPlaceHolder != null)  {
        el.setAttribute('tabindex', tabindexPlaceHolder);
        el.removeAttribute('tabindexPlaceHolder');
      }
      else 
        el.removeAttribute('tabindex');
    });
  }
}
