<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h1 class="title" >
            <p innerHtml="{{'OHS.Title' | translate}}"></p>
        </h1>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <p innerHtml="{{'OHS.Content1' | translate}}"></p>
            <br>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <p innerHtml="{{'OHS.Content2' | translate}}"></p>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <table>
                <tr>
                    <td><p innerHtml="{{'OHS.English' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Korean' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Spanish' | translate}}"></p></td>
                </tr>
                <tr>
                    <td><p innerHtml="{{'OHS.French' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Igbo (Nigerian)' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Tagalog' | translate}}"></p></td>
                </tr>
                <tr>
                    <td><p innerHtml="{{'OHS.Arabic' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Oji-Cree' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Tamil' | translate}}"></p></td>
                </tr>                
                <tr>
                    <td><p innerHtml="{{'OHS.Chinese (Traditional)' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Polish' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Thai' | translate}}"></p></td>
                </tr>               
                <tr>
                    <td><p innerHtml="{{'OHS.Chinese (Simplified)' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Portuguese' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Ukrainian' | translate}}"></p></td>
                </tr>                
                <tr>
                    <td><p innerHtml="{{'OHS.Hindi' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Punjabi' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Urdu' | translate}}"></p></td>
                </tr>               
                 <tr>
                    <td><p innerHtml="{{'OHS.Italian' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Russian' | translate}}"></p></td>
                    <td><p innerHtml="{{'OHS.Vietnamese' | translate}}"></p></td>
                </tr>
            </table>
        </div>
      </div>
    
    <div class="row">
        <div class="ontario-columns">
            <div class="img-container" *ngIf="EnPage"><img src="/assets/images/ohs_en.jpg" alt="Health & safety at work: Prevention Starts Here poster preview" class="scaled-image" id="prevention_img"></div>
            <div class="img-container" *ngIf="FrPage"><img src="/assets/images/ohs_fr.jpg" alt="Santé et sécurité au travail La Prévention Commence Ici aperçu de l’affiche" class="scaled-image" id="prevention_img"></div>
        </div>
    </div>
</div>
