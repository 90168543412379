import { Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PublicSecureService } from '../public-secure/public-secure.service';
import { CookieService } from 'ngx-cookie-service';
import { IdleTimeoutService } from '../idle-timeout/idle-timeout.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public translateService: TranslateService, public publicSecureService: PublicSecureService, private cookieService: CookieService, private idleTimeoutService: IdleTimeoutService) { }
  
  public loggedInStatus = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInStatus.asObservable();
  public userEmailSignal = signal('')
  public cookieExpiry!: number;

  login(sourceUrl: string, lang: string) {
    let state = {sourceUrl: encodeURIComponent(sourceUrl)}
    window.location.href = ("https://" + window.location.host + "/login?lang=" + lang + "&state=" + JSON.stringify(state))
  }

  setUserEmail(email: string) {
    this.userEmailSignal.set(email)
  }

  setLoggedIn(loggedIn: boolean) {
    this.loggedInStatus.next(loggedIn);
  }

  setSessionExpiry(expiry: number) {
    this.cookieExpiry = expiry
  }

  sessionToExpireWithinTime(): number {
    var now = new Date()
    var utcDate = (Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())) / 1000
    return Math.floor((this.cookieExpiry - utcDate) / 60)
  }

  gotoLogout() {
    // this.googleAnalyticsService.eventEmitterWithUserEmail('logout', 'Authentication', 'logout', null, null, this.getUserEmail())
    this.idleTimeoutService.stopTimer();
    this.setUserEmail("");
    this.cookieService.deleteAll('/');
    let lang = this.translateService.currentLang == 'fr' ? 'fr' : 'en';
    window.location.href = ("https://" + window.location.host + "/signout?lang=" + lang);
  }

  isSessionExpiryValid(): boolean {
    var now = new Date()
    var utcDate = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())
    return this.cookieExpiry != null && this.cookieExpiry != undefined && this.cookieExpiry > (utcDate / 1000);
  }

  isSessionExpiryNull(): boolean {
    return this.cookieExpiry == null || this.cookieExpiry == undefined
  }

  gotoEditProfile() {
    let redirectUrl = '';
    this.publicSecureService.getUserProfileUrl().subscribe(data => {
        redirectUrl = data?.value;
        let status = data?.message;

        if (status != null && status.toLowerCase() == 'success')
          window.location.href = (redirectUrl);
      });
  }

}
