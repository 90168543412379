<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div *ngIf="showSuccessAlert" id="sucessPageAlert" class="ontario-alert ontario-alert--success">
            <div class="ontario-alert__header">
                <div class="ontario-alert__header-icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                        viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-success"></use>
                    </svg>
                </div>
                <h2 *ngIf="showAddedUser" class="ontario-alert__header-title ontario-h4">{{'WorkspaceManagement.UserHasBeenAdded' | translate}}</h2>
                <h2 *ngIf="showRemoveUser" class="ontario-alert__header-title ontario-h4">{{'WorkspaceManagement.UserHasBeenRemoved' | translate}}</h2>
                <h2 *ngIf="showEmailPreferenceUpdated" class="ontario-alert__header-title ontario-h4">{{'WorkspaceManagement.EmailPreferenceUpdated' | translate}}</h2>
            </div>
            <div class="ontario-alert__body">
                <p *ngIf="showAddedUser">{{'WorkspaceManagement.UserHasBeenAddedSummary' | translate}}</p>
                <p *ngIf="showRemoveUser">{{'WorkspaceManagement.UserHasBeenRemovedSummary' | translate}}</p>
                <p *ngIf="showEmailPreferenceUpdated">{{'WorkspaceManagement.EmailPreferenceUpdatedSummary' | translate}}</p>
            </div>
        </div>
        <div class="ontario-columns top-banner">
            <h1 class="title">
            {{'WorkspaceManagement.AccountSettings' | translate}}
            </h1>          
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <p class="ontario-lead-statement">{{'WorkspaceManagement.Summary' | translate}}</p><br />
            <div class="list-header">
                <h2>{{'WorkspaceManagement.YourTeam' | translate}}</h2>  
                <button class="ontario-button ontario-button--primary add-button" (click)="openAddDialog()">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-add"></use>
                    </svg>{{ 'Buttons.AddTeamMember' | translate}}
                </button>
            </div>
        </div> 
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-table-container ontario-small-12 ontario-medium-12 ontario-large-12">
                <div class="ontario-table-div">
                    <table class="user-table" tabindex="-1">
                        <thead>
                            <tr>
                                <th scope="col">
                                    {{'WorkspaceManagement.Email' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of users; let i = index">
                                <td  class="user-table-row" scope="row">
                                    <span>
                                        <b>{{user.userEmail}} </b>
                                        <span *ngIf="!user.hasLoggedIn" class="ontario-badge ontario-badge--default-light">{{'WorkspaceManagement.Invited' | translate}}</span>
                                    </span>
                                    <div class="buttons">
                                        <button *ngIf="user.userEmail == currentUser.Email" class="ontario-button ontario-button--secondary" (click)="openEmailPreferenceDialog()">
                                            {{'WorkspaceManagement.EmailPreferences' | translate}}
                                        </button>
                                        <button *ngIf="shouldShowRemoveButton() || user.userEmail != currentUser.Email" class="ontario-button ontario-button--tertiary" (click)="openRemoveDialog(i)">
                                            {{ 'WorkspaceManagement.Remove' | translate}}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>          
        </div>
    </div>
</div>
       