import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { inject } from '@angular/core';

declare function delay(time: any): Promise<any>;

@Component({
  selector: 'app-confirmation-close-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule],
  templateUrl: './confirmation-close-dialog.component.html',
  styleUrls: ['./confirmation-close-dialog.component.scss']
})
export class ConfirmationCloseDialogComponent {
  inactive: number
  mins: number
  isError: boolean = true
  data = inject(MAT_DIALOG_DATA)

  constructor(public dialogRef: MatDialogRef<ConfirmationCloseDialogComponent>,
              private translateService: TranslateService) {
    
    this.inactive = this.data.inactive
    this.mins = this.data.mins

    if (this.data.mins > 0) {
      this.isError = false

      delay(60000 * this.data.mins).then(() => {
        this.isError = true
      })
    }
  }

  close() {
    this.dialogRef.close(!this.isError); //return true if warning, return false if error
  }
}
