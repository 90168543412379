<div class="ontario-form-group">
    <div class="closeButton">
        <h3>{{'WorkspaceManagement.EmailSettings' | translate}}</h3>
        <button (click)="onCloseClick()" aria-label="Close">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-close"></use>
            </svg>
        </button>
    </div>
    <fieldset class="ontario-fieldset">
        <div class="ontario-checkboxes">
            <div class="ontario-checkboxes__item">
                <input class="ontario-checkboxes__input" id="checkbox-option-2" name="importantOccupationalTopicsOption" type="checkbox" value="importantOccupationalTopics" [(ngModel)]="emailPreferences.isEmailCommunicationAboutLegislationChangesEnabled">
                <label class="ontario-checkboxes__label" for="checkbox-option-2">
                    {{'WorkspaceManagement.EmailPreferenceOption2' | translate}}
                </label>
            </div>
            <div class="ontario-checkboxes__item">
                <input class="ontario-checkboxes__input" id="checkbox-option-1" name="remindersToReviewOption" type="checkbox" value="remindersToReview" [(ngModel)]="emailPreferences.isPeriodicEmailForPlanEffectivenesUpdateEnabled">
                <label class="ontario-checkboxes__label" for="checkbox-option-1">
                    {{'WorkspaceManagement.EmailPreferenceOption1' | translate}}
                </label>
            </div>
            <div class="ontario-checkboxes__item">
                <input class="ontario-checkboxes__input" id="checkbox-option-3" name="participationInSurveysOption" type="checkbox" value="participationInSurveys" [(ngModel)]="emailPreferences.isUserResearchParticipationEnabled">
                <label class="ontario-checkboxes__label" for="checkbox-option-3">
                    {{'WorkspaceManagement.EmailPreferenceOption3' | translate}}
                </label>
            </div>
        </div>
    </fieldset>
    <br />
    <div class="ontario-row">
        <div class="ontario-columns ontario-small-12">
            <button (click)="submitClick()" class="ontario-button ontario-button--primary">
                {{'WorkspaceManagement.SaveAndClose' | translate}}
            </button>
            <button (click)="onCloseClick()" class="ontario-button ontario-button--secondary">
                {{'WorkspaceManagement.Cancel' | translate}}
            </button>
        </div>
    </div>
    
</div>