<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{'ImportantNotes.Heading' | translate}}
          </h1>          
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <p class="item-top-text" innerHtml="{{'ImportantNotes.Summary' | translate}}"></p>
            <br />
            <button id=continue class="ontario-button ontario-button--primary" (click)="saveAndContinue()">
                {{ 'Buttons.Confirm' | translate}}
            </button>
        </div>
    </div>
</div>