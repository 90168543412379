import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, shareReplay, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BusinessModuleModel, SectionModel } from 'src/app/models/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessModuleService {

  private cachedBusinessModules: any = null;
  private cachedModule: any = null;

  constructor(private httpClient: HttpClient) { }

  invalidateCachedBusinessModules() {
    this.cachedBusinessModules = null;
  }

  invalidateCachedModule() {
    this.cachedModule = null;
  }

  getBusinessModule(businessModuleId: string): Observable<BusinessModuleModel> {
    return this.httpClient.get<BusinessModuleModel>(`${environment.apiBaseUrl}businessmodule/withBmId/${businessModuleId}`);
  }

  getModule(moduleId: string): Observable<BusinessModuleModel> {
    if (this.cachedModule && moduleId == this.cachedModule.module.id) {
      return of(this.cachedModule);
    } else {
      return this.httpClient.get<BusinessModuleModel>(`${environment.apiBaseUrl}businessmodule/${moduleId}`).pipe(
        tap(data => this.cachedModule = data),
        shareReplay(1)
      )
    }
  }

  getSection(id: string, section: string): Observable<SectionModel> {
      return this.httpClient.get<SectionModel>(`${environment.apiBaseUrl}businessmodule/${id}/${section}`);
  }

  getBusinessModules(moduleType: string): Observable<BusinessModuleModel[]> {
    if (this.cachedBusinessModules && moduleType == '') {
      return of(this.cachedBusinessModules);
    } else {
      return this.httpClient.get<BusinessModuleModel[]>(`${environment.apiBaseUrl}businessmodule?moduleType=${moduleType}`).pipe(
        tap(data => moduleType == '' && (this.cachedBusinessModules = data)),
        shareReplay(1)
      );
    }
  }

  updateCompleted(moduleId: string, status: boolean): Observable<number> {
    this.invalidateCachedBusinessModules();
    this.invalidateCachedModule();
    return this.httpClient.patch<number>(`${environment.apiBaseUrl}businessmodule/${moduleId}/completedstatus`, {completed: status});
  }

  updateDismissed(moduleId: string, status: boolean): Observable<number> {
    this.invalidateCachedBusinessModules();
    this.invalidateCachedModule();
    return this.httpClient.patch<number>(`${environment.apiBaseUrl}businessmodule/${moduleId}/dismissedstatus`, {dismissed: status});
  }

  addCompletedBusinessModuleSection(businessModuleId: string, sectionId: string): Observable<number> {
    this.invalidateCachedBusinessModules();
    return this.httpClient.post<number>(`${environment.apiBaseUrl}businessmodule/addcompletedsection/`, { businessModuleId: businessModuleId, sectionId: sectionId });
  }

  addCustomHazard(name: string, description: string): Observable<number> {
    this.invalidateCachedBusinessModules();
    return this.httpClient.post<number>(`${environment.apiBaseUrl}businessmodule/addCustomHazard`, {name: name, description: description});
  }
}
