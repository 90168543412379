import { ErrorHandler, Injectable } from '@angular/core';
import { SpinnerService } from '../spinner/spinner.service';
import { LoggingService } from '../logging/logging.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(public dialog: MatDialog, private spinnerService: SpinnerService, private loggingService: LoggingService) {
    super();
  }

  override handleError(error: any) {
    console.log(error)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data =
    {
      errorMsg: error
    };
    this.dialog.open(ErrorDialogComponent, dialogConfig);
    this.spinnerService.hide();
    this.loggingService.logException(error);
  }
}