import { Component, forwardRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FeedbackSubmittedDialogComponent } from '../feedback-submitted-dialog/feedback-submitted-dialog.component';
import { HomepageComponent } from '../homepage/homepage.component';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-feedback-starting-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, forwardRef(() => FeedbackDialogComponent), FeedbackSubmittedDialogComponent],
  templateUrl: './feedback-starting-dialog.component.html',
  styleUrls: ['./feedback-starting-dialog.component.scss']
})
export class FeedbackStartingDialogComponent {
  dialogRef: any

  constructor(public currentDialogRef: MatDialogRef<HomepageComponent>,
    public dialog: MatDialog, 
    public authService: AuthService,
    public translate: TranslateService,
    public router: Router,
    private spinnerService: SpinnerService) { }

  onCloseClick(): void {
    this.currentDialogRef.close();
  }

  openDialog(category: string) {
    this.currentDialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = '350px';
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data =
    {
      feedback: "",
      category: category
    };

    this.dialogRef = this.dialog.open(FeedbackDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe((data: any) => {
      this.spinnerService.hide();
        if (data) {
          this.dialog.open(FeedbackSubmittedDialogComponent);
        }
      }
    );
  }
}
