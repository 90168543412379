import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PdfgeneratorService } from 'src/app/services/pdfgenerator/pdfgenerator.service';
import { BusinessService } from 'src/app/services/business/business.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { filter } from 'rxjs';

declare function scrollToError(): void

@Component({
  selector: 'app-healthandsafetypledge',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './healthandsafetypledge.component.html',
  styleUrls: ['./healthandsafetypledge.component.scss']
})
export class HealthandsafetypledgeComponent {
  businessName: string = "";
  ownerName: string = "";
  ownerRole: string = "";
  EnPage: boolean = this.translate.currentLang == 'en' ;
  FrPage: boolean = this.translate.currentLang != 'en' ;

  validationFailed : boolean = false;
  businessNameTooLong: boolean = false;
  nameTooLong: boolean = false;
  roleTooLong: boolean = false;
  businessNameInvalid: boolean = false;
  nameInvalid: boolean = false;
  roleInvalid: boolean = false;
  invalidError: boolean = false;
  currentLanguage: string = '';

  constructor(public authService: AuthService, public translate: TranslateService, public router: Router, public pdfGenerator: PdfgeneratorService, private businessService: BusinessService, private spinnerService: SpinnerService, private validationService: ValidationService) { 
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      const urlParts = this.router.url.split('/');
      this.currentLanguage = urlParts[1];
    });
  }

  ngOnInit() {
    this.populatePledgeFields();
  }

  populatePledgeFields() {
    this.businessService.getBusiness().subscribe((initialAnswers) => {
      if (initialAnswers){
        this.businessName = initialAnswers.step1.businessName  != null ? initialAnswers.step1.businessName : this.businessName;    
        this.ownerName = initialAnswers.pledgeManagementPerson != null ? initialAnswers.pledgeManagementPerson : this.ownerName;
        this.ownerRole = initialAnswers.pledgeManagementRole != null ? initialAnswers.pledgeManagementRole : this.ownerRole; 
      }
    });
  }

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  businessNameCheck() {
    this.businessNameTooLong = false;
    this.businessNameInvalid = false;
    if(this.businessName.trim().length > 100){
      this.businessNameTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.businessName.trim())){
      this.businessNameInvalid = true;
    }
  }

  nameErrorCheck(){
    this.nameTooLong = false;
    this.nameInvalid = false;
    if(this.ownerName.trim().length > 100){
      this.nameTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.ownerName.trim())){
      this.nameInvalid = true;
    }
  }

  roleErrorCheck(){
    this.roleTooLong = false;
    this.roleInvalid = false;
    if(this.ownerRole.trim().length > 100){
      this.roleTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.ownerRole.trim())){
      this.roleInvalid = true;
    }
  }
  
  validationCheck(){
    this.invalidError = false;
    this.businessNameCheck();
    this.nameErrorCheck();
    this.roleErrorCheck();
    this.validationFailed = this.businessNameTooLong || this.nameTooLong || this.roleTooLong || this.businessNameInvalid || this.nameInvalid || this.roleInvalid;
  }

  saveAndContinue() { 
    this.validationCheck();
    if (this.validationFailed) {
      scrollToError();
      return;
    }
    this.updateSafetyPledge();
  }
  
  download() {
    this.validationCheck();
    if (this.validationFailed) {
      scrollToError();
      return;
    }
    this.updateSafetyPledge();
    this.pdfGenerator.downloadPledgePDF(this.businessName.trim(), this.ownerName.trim(), this.ownerRole.trim(), this.currentLanguage)
  }

  downloadWord() {
    const ENurl = '/assets/images/Health and Safety Policy.docx';
    const FRurl = '/assets/images/Health and Safety Policy_FR.docx';

    const a = document.createElement('a');
    a.href = this.translate.currentLang == 'fr' ? FRurl : ENurl;
    a.download = this.translate.currentLang == 'fr' ? 'PMSS_FR.docx' : 'Health and Safety Policy.docx'; 
    a.click();
  }

  updateSafetyPledge() {
    this.businessService.updateSafetyPledge(this.businessName.trim(), this.ownerName.trim(), this.ownerRole.trim()).subscribe({
      next: () => {
        this.validationFailed = false;
      },
      error: (error) => {
        if(error.error?.Message === ValidationService.ServerXssError) {
          this.invalidError = true;
          this.validationFailed = true;
          scrollToError();
        }
        if(error.error?.message === "Validation Errors") {
          error.error.errors.forEach((e: { errorCode: string, errorMessage: string, propertyName: string; }) => {
            Object.assign(this, {[e.errorMessage]: true});
          });
          this.validationFailed = true;
          scrollToError();
        }
        this.spinnerService.hide();
      }
    });
  }

  back() {
  }

}
