import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { filter } from 'rxjs';
import { AnswerService } from 'src/app/services/answer/answer.service';
import { BusinessModuleModel, SectionModel, ControlMeasureModel } from 'src/app/models/models';
import { ControlMeasureService } from 'src/app/services/control-measure/control-measure.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

declare function scrollToError(): void

@Component({
  selector: 'app-hazard-step2',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './hazard-step2.component.html',
  styleUrls: ['./hazard-step2.component.scss']
})
export class HazardStep2Component {
  returnId: string = "";
  businessModule: BusinessModuleModel = {
    id: '',
    completed: false,
    dismissed: false,
    lastUpdatedBy: '',
    lastUpdatedOn: '',
    answers: [],
    module: {
      id: '',
      name: '',
      nameFR: '',
      summary: '',
      summaryFR: '',
      type: '',
      priority: 0,
      imageURL: '',
      dismissible: true
    },
    completedSectionsCount: 0
  };
  section: SectionModel = {
    id: '',
    summary: '',
    summaryFR: '',
    priority: 0,
    type: '',
    moduleId: '',
  };
  ownControlMeasuresTooLong: boolean = false;
  xssCheckFailed: boolean = false;
  controlEmptyError: boolean = false;
  validationFailed: boolean = false;
  hazardId: string = '';
  controlMeasures: ControlMeasureModel[] = [];
  selectedControlMeasuresIds: string[] = [];
  ownControlMeasures: {value: string, validationFailed: boolean}[] = [{ value: '', validationFailed: false }];

  constructor(public translate: TranslateService, private activatedRoute: ActivatedRoute, private businessModuleService: BusinessModuleService, private router: Router, private answerService: AnswerService, private controlMeasureService: ControlMeasureService, private spinnerService: SpinnerService, private validationService: ValidationService) { 
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.ownControlMeasures = [{ value: '', validationFailed: false }];
      const focusedElement = document.activeElement as HTMLElement;
      focusedElement.blur();
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hazardId = params['id'];
      this.businessModuleService.getModule(this.hazardId).subscribe(businessModule => {
        this.businessModule = businessModule;
        this.controlMeasureService.getControlMeasure(businessModule.module.id).subscribe(cm => {
          this.controlMeasures = cm.sort((a, b) => a.priority - b.priority);
          this.controlMeasureService.getSelectedControlMeasures(businessModule.id).subscribe(bmCm => {
            for (const b of bmCm)
              this.onCheckboxChange(b.controlMeasureID);
          });
        });
        this.businessModuleService.getSection(this.hazardId, "2").subscribe(section => {
          this.section = section;
          this.answerService.getAnswers(section.id, businessModule.id).subscribe(data => {
            if (data.length != 0) {
              this.ownControlMeasures = [];
              for (const questionAnswer of data){
                this.ownControlMeasures.push({ value: questionAnswer.answerText, validationFailed: false });
              }
            }
          })
        })
      });
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['return'] != undefined)
        this.returnId = params['return'];
    });
  }
  
  addAnswer() {
      this.ownControlMeasures.push({ value: '', validationFailed: false });
  }

  removeAnswer(index: number) {
      this.ownControlMeasures.splice(index, 1);
  }

  onCheckboxChange(optionValue: string) {
    if (this.selectedControlMeasuresIds.includes(optionValue)) {
      this.selectedControlMeasuresIds = this.selectedControlMeasuresIds.filter(val => val !== optionValue);
    } else {
      this.selectedControlMeasuresIds.push(optionValue);
    }
  }

  saveAndContinue() {
    let questionAnswer: {answerText: string, questionId: number, answerOrder: number}[] = [];
    this.ownControlMeasuresTooLong = false;
    this.validationFailed = false;
    this.controlEmptyError = false;
    this.xssCheckFailed = false;
    
    if (this.selectedControlMeasuresIds.length < 1)
      this.controlEmptyError = true;
    
    for (const answer of this.ownControlMeasures) {
      answer.validationFailed = false;
      if (answer.value.trim() !== '') {
        if (answer.value.trim().length > 80) {
          answer.validationFailed = true;
          this.ownControlMeasuresTooLong = true;
        } else if (!this.validationService.hasPassedXssValidation(answer.value.trim()) && !this.ownControlMeasuresTooLong) {
          this.xssCheckFailed = true;
        }
        questionAnswer.push({
          answerText: answer.value.trim(),
          questionId: 2,
          answerOrder: questionAnswer.length + 1
        });
      }
    }

    if (questionAnswer.length > 0)
      this.controlEmptyError = false;

    if (this.ownControlMeasuresTooLong || this.controlEmptyError || this.xssCheckFailed) {
      this.validationFailed = true;
      scrollToError();
    } 
    else {
      this.answerService.addAnswers(this.section.id, this.businessModule.id, questionAnswer).subscribe({
        next: () => {
          this.businessModuleService.addCompletedBusinessModuleSection(this.businessModule.id, this.section.id).subscribe(_ => {});
          this.controlMeasureService.changeSelectedControlMeasures(this.businessModule.id, this.selectedControlMeasuresIds).subscribe(_ =>{});
          this.businessModuleService.updateCompleted(this.businessModule.module.id, true).subscribe(() => {
            if(this.returnId != "")
              this.router.navigate(['../../../safetyplans/newplanstep2/', this.returnId], { relativeTo: this.activatedRoute });
            else
              this.router.navigate(['../../', 'summary', this.hazardId], { relativeTo: this.activatedRoute });
          });
      },
      error: (error) => {
        if(error.error?.Message === ValidationService.ServerXssError) {
          this.xssCheckFailed = true;
          this.validationFailed = true;
          scrollToError();
        }
        this.spinnerService.hide();
      }
    });
  }
}

  back() {
    if (this.returnId != "")
      this.router.navigate(['../../', 1, this.hazardId], { queryParams: {return: this.returnId}, relativeTo: this.activatedRoute });
    else
      this.router.navigate(['../../', 1, this.hazardId], { relativeTo: this.activatedRoute });
  }

  scrollToHazardAnswer(questionId: string) {
    const element = document.getElementById(questionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
