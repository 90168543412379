<div class="ontario-form-group">
    <div class="closeButton">
        <button (click)="onCloseClick()" aria-label="Close">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-close"></use>
            </svg>
        </button>
    </div>
    <div class="dialogBody">
        <svg class="ontario-icon" alt="">
            <use href="#ontario-icon-alert-success"></use>
        </svg>
        <h5>{{'Feedback.WeAppreciateTheFeedback' | translate}}</h5>
        <button (click)="onNoClick()" class="ontario-button ontario-button--primary">
            {{'Feedback.SendMoreFeedback' | translate}}
        </button>
    </div>
</div>