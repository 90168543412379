<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h1 class="title" >
            <p innerHtml="{{'JHSC.Title' | translate}}"></p>
        </h1>
        <div class="ontario-lead-statement leadText" >
            <p innerHtml="{{'JHSC.Subtitle' | translate}}"></p>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <p innerHtml="{{'JHSC.Content1' | translate}}"></p>        
        </div>
    </div>
</div>