import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { BusinessService } from 'src/app/services/business/business.service';
import { PdfgeneratorService } from 'src/app/services/pdfgenerator/pdfgenerator.service';
import { filter } from 'rxjs';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

declare function scrollToError(): void

@Component({
  selector: 'app-healthandsafetypledge',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './violenceandharassment.component.html',
  styleUrls: ['./violenceandharassment.component.scss']
})
export class ViolenceandharassmentComponent {
  businessName: string = "";
  employerName: string = "";
  EnPage: boolean = this.translate.currentLang == 'en';
  FrPage: boolean = this.translate.currentLang != 'en';
  currentLanguage: string = '';
  nameInvalid: boolean = false;
  nameTooLong: boolean = false;
  validationFailed: boolean = false;
  
  constructor(public autService: AuthService, public translate: TranslateService, public router: Router, private businessService: BusinessService, public pdfGenerator: PdfgeneratorService, private validationService: ValidationService, private spinnerService: SpinnerService) { 
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      const urlParts = this.router.url.split('/');
      this.currentLanguage = urlParts[1];
    });
  }

  ngOnInit() {
    this.businessService.getBusiness().subscribe((business) => {
      if (business != null)
        this.businessName = business.step1.businessName != null ? business.step1.businessName : this.businessName;
        this.employerName = business.pledgeManagementPerson != null ? business.pledgeManagementPerson : "";
    })
  }

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  nameErrorCheck(){
    this.validationFailed = false;
    this.nameTooLong = false;
    this.nameInvalid = false;
    if(this.businessName.trim().length > 100){
      this.nameTooLong = true;
      this.validationFailed = true;
    } else if(!this.validationService.hasPassedXssValidation(this.businessName.trim())){
      this.nameInvalid = true;
      this.validationFailed = true;
    }
  }

  saveAndClose() {
    this.nameErrorCheck();
    if (this.validationFailed) {
      scrollToError();
      return;
    }
    this.updateBusinessName();
  }

  handlePDFActions() {
    this.nameErrorCheck();
    if (this.validationFailed) {
      scrollToError();
      return;
    }
    this.updateBusinessName();
    this.pdfGenerator.downloadViolencePDF(this.businessName, this.employerName, this.currentLanguage);
  }

  downloadWord() {
    const ENurl = '/assets/images/WHVP.docx';
    const FRurl = '/assets/images/WHVP_FR.docx';

    const a = document.createElement('a');
    a.href = this.translate.currentLang == 'fr' ? FRurl : ENurl;
    a.download = this.translate.currentLang == 'fr' ? 'PCVH_FR.docx' : 'WHVP.docx'; 

    a.click();
  }

  updateBusinessName() {
    this.businessService.updateBusinessName(this.businessName.trim()).subscribe({
      next: () => {
        this.validationFailed = false;
      },
      error: (error) => {
        if(error.error?.Message === ValidationService.ServerXssError) {
          this.nameInvalid = true;
          this.validationFailed = true;
          scrollToError();
        }
        if(error.error?.message === "Validation Errors") {
          error.error.errors.forEach((e: { errorCode: string, errorMessage: string, propertyName: string; }) => {
            Object.assign(this, {[e.errorMessage]: true});
          });
          this.validationFailed = true;
          scrollToError();
        }
        this.spinnerService.hide();
      }
    });
  }
}