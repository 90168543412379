import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


export const localizationGuard: CanActivateFn = (route, state) => {
  const translate = inject(TranslateService)
  const url = state.url;

  if (url.includes('/fr')) {
    translate.setDefaultLang('fr');
    translate.use('fr');
    translate.currentLang = 'fr';
  } else {
    translate.setDefaultLang('en');
    translate.use('en');
    translate.currentLang = 'en';
  }
  return true;
};
