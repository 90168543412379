import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { BusinessService } from 'src/app/services/business/business.service';

@Component({
  selector: 'app-importantnotes',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './importantnotes.component.html',
  styleUrls: ['./importantnotes.component.scss']
})
export class ImportantnotesComponent {
  constructor(public translate: TranslateService, private router: Router, private onboardingService: OnboardingService, private businessService: BusinessService) { }

  saveAndContinue() {
    this.onboardingService.checkBusinessExists().subscribe((businessExists) => {
      if (businessExists.value === "Yes") {
        console.log("A business already exists for the user.");
      } else {
        this.businessService.createBusiness().subscribe(() => {
          this.router.navigate([this.translate.currentLang == 'en' ? 'step1' : 'fr/step1']);
        });
      }
    });
  }
}
