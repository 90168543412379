import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    static readonly ServerXssError = "Error from AntiXssMiddleware";
    static readonly ServerValidationErrors = "Validation Errors";

    constructor(private sanitizer: DomSanitizer) {}

    hasPassedXssValidation(value: string) {
        // value does not contain either of the dangerous characters.
        if (!(value.includes("<") || value.includes("&"))) return true;


        for(var i = 0; i < value.length; i++) {
            var character = value.charAt(i);
            var nextCharacter = value.charAt(i + 1);
            
            if(character === '<' && (nextCharacter.match(/[a-zA-Z]/) || nextCharacter === '!' || nextCharacter === '/' || nextCharacter === '?'))
                return false;
            
            if(character == '&' && nextCharacter === "#")
                return false;
        }

        return true;
    }
}