import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {HomepageComponent } from '../homepage/homepage.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FeedbackStartingDialogComponent } from '../feedback-starting-dialog/feedback-starting-dialog.component';

@Component({
  selector: 'app-feedback-submitted-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, HomepageComponent, TranslateModule],
  templateUrl: './feedback-submitted-dialog.component.html',
  styleUrls: ['./feedback-submitted-dialog.component.scss']
})
export class FeedbackSubmittedDialogComponent {

  constructor(public currentDialogRef: MatDialogRef<HomepageComponent>, public translate: TranslateService, public dialog: MatDialog) { 
  }

  onCloseClick(): void {
    this.currentDialogRef.close();
  }

  onNoClick(): void {
    this.currentDialogRef.close();
    this.dialog.open(FeedbackStartingDialogComponent);
  }
}
