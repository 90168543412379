import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SafetyPlanService } from 'src/app/services/safetyplan/safetyplan.service';
import { BusinessService } from 'src/app/services/business/business.service';

declare function scrollToError(): void

@Component({
  selector: 'app-newplanstep4',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './newplanstep4.component.html',
  styleUrls: ['./newplanstep4.component.scss']
})
export class Newplanstep4Component {
  constructor(
    private translate: TranslateService,
    private safetyPlanService: SafetyPlanService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private businessService: BusinessService
  ) { }

  selectedOptions: string[] = [];
  newPlanId!: string;

  validationFailed: boolean = false;
  Q1RadioValue: string = '';
  Q1RadioInvalid: boolean = false;
  selectedOptionsInvalid: boolean = false;
  otherOption: string = '';
  otherInputEmpty: boolean = false;
  otherInputTooLong: boolean = false;
  otherInputValidationFailed: boolean = false;
  otherInterval: string = '';
  otherIntervalEmpty: boolean = false;
  otherIntervalTooLong: boolean = false;
  otherIntervalValidationFailed: boolean = false;
  @ViewChild('errorHeading', { read: ElementRef }) errorHeading! : ElementRef;
    
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.newPlanId = params['id'];
      if (this.newPlanId) {
        this.loadPlanDetails(this.newPlanId);
      }
    });
  }
  
  loadPlanDetails(planId: string) {
    this.safetyPlanService.getSafetyPlan(planId).subscribe((details: any) => {
      this.businessService.getBusiness().subscribe(b => {
        if (details && b.id == details.businessId) {
          this.selectedOptions = details.planEvaluationOptions || [];
          this.Q1RadioValue = details.whenIsPlanReviewed || '';
          this.otherOption = details.planEvaluationOtherOption || '';
          this.otherInterval = details.planEvaluationOtherInterval || '';
        }
        else 
            this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
      });
    });
  }
  
  resetFields() {
    this.validationFailed = false
    this.Q1RadioInvalid = false
    this.otherInputEmpty = false;
    this.otherInputTooLong = false;
    this.otherInputValidationFailed = false;
    this.otherIntervalEmpty = false;
    this.otherIntervalTooLong = false;
    this.otherIntervalValidationFailed = false;
  }
 
  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.focus();
    }
  }

  validateCheckboxes() {
    this.selectedOptionsInvalid = this.selectedOptions.length === 0;
    this.Q1RadioInvalid = !this.Q1RadioValue;
    this.validationFailed = this.selectedOptionsInvalid || this.Q1RadioInvalid;
  }

  back() {
    this.router.navigate(['../../newplanstep3', this.newPlanId], { relativeTo: this.activatedRoute });
  }

  saveAndContinue() {
    this.resetFields();

    this.validateCheckboxes();    
    
    if(this.selectedOptions.includes('other') && this.otherOption.trim() === ''){
      this.validationFailed = true;
      this.otherInputEmpty = true;
    }

    if(this.selectedOptions.includes('other') && this.otherOption.trim().length > 150){
      this.validationFailed = true;
      this.otherInputTooLong = true;
    }

    if(this.Q1RadioValue === 'other' && this.otherInterval.trim() === ''){
      this.validationFailed = true;
      this.otherIntervalEmpty = true;
    }

    if(this.Q1RadioValue === 'other' && this.otherInterval.trim().length > 150){
      this.validationFailed = true;
      this.otherIntervalTooLong = true;
    }

    if (this.validationFailed) {
      this.changeDetector.detectChanges();
      this.errorHeading?.nativeElement?.focus();
      this.errorHeading?.nativeElement?.scrollIntoView();      

    } else {
      this.safetyPlanService.updateStep4Plan(this.newPlanId, this.Q1RadioValue, this.selectedOptions, this.otherOption, this.otherInterval).subscribe(() => {
        this.router.navigate(['../../view', this.newPlanId], { relativeTo: this.activatedRoute });
      });
    }
  }

  onCheckboxChange(optionValue: string) {
    if (this.selectedOptions.includes(optionValue)) {
      this.selectedOptions = this.selectedOptions.filter(val => val !== optionValue);
      if(optionValue === 'other') {
        this.otherOption = '';
      }
    } else {
      this.selectedOptions.push(optionValue);
    }
  }
}

