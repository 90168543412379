import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BusinessService } from 'src/app/services/business/business.service';

@Component({
  selector: 'app-safetyboard',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
  templateUrl: './safetyboard.component.html',
  styleUrls: ['./safetyboard.component.scss']
})
export class SafetyboardComponent {
  isWSIBClient: boolean = false;
  constructor(public dialog: MatDialog, public authService: AuthService, public translate: TranslateService, public router: Router, private businessService: BusinessService) { }

  ngOnInit(): void {
    this.businessService.getBusiness().subscribe(d => {
      if (d != null)
        this.isWSIBClient = d.step1.isWSIBClient === "Yes" ? true : false;
    });
  }
}
