import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ValidationService } from 'src/app/services/validation/validation.service';


declare function scrollToError(): void

@Component({
  selector: 'app-hazard-custom',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './hazard-custom.component.html',
  styleUrls: ['./hazard-custom.component.scss']
})
export class HazardCustomComponent {

  xssCheckFailed: boolean = false;
  validationFailed: boolean = false;
  nameTooLongError: boolean = false;
  nameEmptyError: boolean = false;
  nameInvalidError: boolean = false;
  descriptionTooLongError: boolean = false;
  descriptionInvalidError: boolean = false;
  hazardName: string = "";
  hazardDescription: string = "";
  
  constructor(public translate: TranslateService, public router: Router, public activatedRoute: ActivatedRoute, private businessModuleService: BusinessModuleService, private spinnerService: SpinnerService, private validationService: ValidationService){
  }

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
    
  validationCheck() {
    this.xssCheckFailed = false;
    this.validationFailed = false;
    this.nameTooLongError = false;
    this.nameEmptyError = false;
    this.nameInvalidError = false;
    this.descriptionTooLongError = false;
    this.descriptionInvalidError = false;

    if(this.hazardName.trim().length == 0)
      this.nameEmptyError = true;
    else if(this.hazardName.trim().length > 200)
      this.nameTooLongError = true;
    else if(!this.validationService.hasPassedXssValidation(this.hazardName.trim()))
      this.nameInvalidError = true;

    if(this.hazardDescription.trim().length > 450)
      this.descriptionTooLongError = true;
    else if(!this.validationService.hasPassedXssValidation(this.hazardDescription.trim()))
      this.descriptionInvalidError = true;


    this.validationFailed = this.nameEmptyError || this.nameTooLongError || this.nameInvalidError || this.descriptionTooLongError || this.descriptionInvalidError;
  }

  create() { 
    this.validationCheck();
    if (this.validationFailed) {
      scrollToError();
      return;
    }
    this.businessModuleService.addCustomHazard(this.hazardName.trim(), this.hazardDescription.trim()).subscribe({
      next: () => {
        this.router.navigate(['../../hazards'], { relativeTo: this.activatedRoute });
      },
      error: (error) => {
        if(error.error?.Message === ValidationService.ServerXssError) {
          this.xssCheckFailed = true;
          this.validationFailed = true;
          scrollToError();
        }
        if(error.error?.message === "Validation Errors") {
          error.error.errors.forEach((e: { errorCode: string, errorMessage: string, propertyName: string; }) => {
            Object.assign(this, {[e.errorMessage]: true});
          });
          this.validationFailed = true;
          scrollToError();
        }
        this.spinnerService.hide();
      }
    });
  }
  
  back() {
        this.router.navigate(['../../hazards'], { relativeTo: this.activatedRoute });
  }
}
