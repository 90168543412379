import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SafetyPlanService } from 'src/app/services/safetyplan/safetyplan.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SafetyPlanDTOModel } from 'src/app/models/models';

declare function scrollToError(): void

@Component({
  selector: 'app-newplanstep1',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './newplanstep1.component.html',
  styleUrls: ['./newplanstep1.component.scss']
})
export class Newplanstep1Component {

  constructor(private translate: TranslateService, 
              private router: Router, 
              private activatedRoute: ActivatedRoute, 
              private safetyPlanService: SafetyPlanService, 
              private validationService: ValidationService, 
              private spinnerService: SpinnerService,
              private changeDetector: ChangeDetectorRef
            ) { }

  validationFailed: boolean = false;
  planNameValue: string = '';
  contactNameValue: string = '';
  contactNumberValue: string = '';
  contactEmailValue: string = '';
  notesValue: string = '';
  selectedOptions: string[] = [];;
  existingPlanId: string = '';

  invalidCharacterError: boolean = false;
  planNameTooLong: boolean = false;
  planNameInvalid: boolean = false;
  contactNameEmpty: boolean = false;
  contactNameInvalid: boolean = false;
  contactNameTooLong: boolean = false;
  contactNumberEmpty: boolean = false;
  contactNumberInvalid: boolean = false;
  contactNumberTooLong: boolean = false;
  contactEmailXssFailed: boolean = false;
  contactEmailInvalid: boolean = false;
  contactEmailTooLong: boolean = false;
  contactEmailEmpty: boolean = false;
  noteInvalid: boolean = false;
  noteTooLong: boolean = false;
  @ViewChild('errorHeading', { read: ElementRef }) errorHeading! : ElementRef;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.existingPlanId = params['id'];
      if (this.existingPlanId) {
        this.loadPlanDetails(this.existingPlanId);
      }
    });
  }

  loadPlanDetails(planId: string) {
    this.safetyPlanService.getPlanDetails(planId).subscribe((details: any) => {
        if (details) {
          this.planNameValue = details.planName;
          this.contactNameValue = details.contactName;
          this.contactNumberValue = details.contactNumber;
          this.contactEmailValue = details.contactEmail;
          this.notesValue = details.notes;
        }
        else 
          this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
    });
  }

  resetFields() {
    this.validationFailed = false;
    this.invalidCharacterError = false;
    this.planNameTooLong = false;
    this.planNameInvalid = false;
    this.contactNameEmpty = false;
    this.contactNameInvalid = false;
    this.contactNameTooLong = false;
    this.contactNumberEmpty = false;
    this.contactNumberInvalid = false;
    this.contactNumberTooLong = false;
    this.contactEmailXssFailed = false;
    this.contactEmailInvalid = false;
    this.contactEmailTooLong = false;
    this.contactEmailEmpty = false;
    this.noteInvalid = false;
    this.noteTooLong = false;
  }

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.focus();
    }
  }

  saveAndContinue() {
    const emailRegex: RegExp = /^$|^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    this.resetFields();

    if (this.planNameValue.trim().length > 100) {
      this.validationFailed = true;
      this.planNameTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.planNameValue.trim())){
      this.validationFailed = true;
      this.planNameInvalid = true;
    }

    if (this.contactNameValue.trim() == "") {
      this.validationFailed = true;
      this.contactNameEmpty = true;
    } else if (this.contactNameValue.trim().length > 100) {
      this.validationFailed = true;
      this.contactNameTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.contactNameValue.trim())){
      this.validationFailed = true;
      this.contactNameInvalid = true;
    }

    if (this.contactNumberValue.trim() == "") {
      this.validationFailed = true;
      this.contactNumberEmpty = true;
    } else if (this.contactNumberValue.trim().length > 100) {
      this.validationFailed = true;
      this.contactNumberTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.contactNumberValue.trim())){
      this.validationFailed = true;
      this.contactNumberInvalid = true;
    }

    if (this.contactEmailValue.trim() == "") {
      this.validationFailed = true;
      this.contactEmailEmpty = true;
    } else if (this.contactEmailValue.trim().length > 100) {
      this.validationFailed = true;
      this.contactEmailTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.contactEmailValue.trim())) {
      this.validationFailed = true;
      this.contactEmailXssFailed = true;
    } else if (!(emailRegex.test(this.contactEmailValue.trim()))) {
      this.validationFailed = true;
      this.contactEmailInvalid = true;
    } 

    if (this.notesValue.trim().length > 200) {
      this.validationFailed = true;
      this.noteTooLong = true;
    } else if(!this.validationService.hasPassedXssValidation(this.notesValue.trim())){
      this.validationFailed = true;
      this.noteInvalid = true;
    }

    if (this.validationFailed) {
      this.changeDetector.detectChanges();
      this.errorHeading?.nativeElement?.focus();
      this.errorHeading?.nativeElement?.scrollIntoView();
      
    } else {
      let safetyPlan: SafetyPlanDTOModel = {
        planName: this.planNameValue.trim(),
        contactName: this.contactNameValue.trim(),
        contactNumber: this.contactNumberValue.trim(),
        contactEmail: this.contactEmailValue.trim(),
        notes: this.notesValue.trim(),
        id: this.existingPlanId,
        
      }
      this.safetyPlanService.addNewOrUpdateStep1Plan(safetyPlan).subscribe({
        next: (newPlanId: string) => {
          this.validationFailed = false;
        if (this.existingPlanId != null) 
          this.router.navigate(['../../newplanstep2', newPlanId], { relativeTo: this.activatedRoute });
        else 
          this.router.navigate([`../newplanstep2/${newPlanId}`], { relativeTo: this.activatedRoute });
        },
        error: (error) => {
          if(error.error?.Message === ValidationService.ServerXssError) {
            this.validationFailed = true;
            this.invalidCharacterError = true;
            scrollToError();
          }
          if(error.error?.message === "Validation Errors") {
            error.error.errors.forEach((e: { errorCode: string, errorMessage: string, propertyName: string; }) => {
              Object.assign(this, {[e.errorMessage]: true});
            });
            this.validationFailed = true;
            scrollToError();
          }
          this.spinnerService.hide();
        }
      });
    }
  }
}