import { Injectable } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  public timerSubscription!: Subscription;
  public oneMinutePassed = new Subject<boolean>();
  
  constructor() {
    this.oneMinutePassed.subscribe();
  }

  public startTimer() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();

    this.timerSubscription = timer(60 * 1000).subscribe(n => {
      this.oneMinutePassed.next(true);
      this.startTimer()
    }); 
  }

  public stopTimer() {
    this.oneMinutePassed.unsubscribe();
    this.timerSubscription.unsubscribe();
  }
}