import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from 'src/app/services/auth/auth.service';
import { BusinessService } from 'src/app/services/business/business.service';

@Component({
  selector: 'app-workspace-remove-confirm-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule],
  templateUrl: './workspace-remove-confirm-dialog.component.html',
  styleUrls: ['./workspace-remove-confirm-dialog.component.scss']
})
export class WorkspaceRemoveConfirmDialogComponent {
  data: {email: string, currentUser: boolean} = inject(MAT_DIALOG_DATA);

  constructor(public currentDialogRef: MatDialogRef<WorkspaceRemoveConfirmDialogComponent>, public dialog: MatDialog, private businessService: BusinessService, public translate: TranslateService, public router: Router, public authService: AuthService) { }

  onCloseClick(): void {
    this.currentDialogRef.close();
  }

  deleteUser() {
    this.businessService.removeUserFromBusiness(this.data.email).subscribe(() =>{
      if (this.data.currentUser)
        this.authService.gotoLogout();
      else
        this.currentDialogRef.close('Remove');
    });
  }
}
