import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { UserModel } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private cachedUserInfo: any = null;

  constructor(private httpClient: HttpClient) {
  }

  updateUserInformation(user: UserModel): Observable<number>{
    return this.httpClient.post<number>(environment.apiBaseUrl + `user`, user);
  }

  getUserInfo(): Observable<any>{
    const url=`${environment.apiBaseUrl}user/getUserInfo`;

    if (this.cachedUserInfo) {
      return of(this.cachedUserInfo);
    } else {
      return this.httpClient.get(url).pipe(
        tap(data => this.cachedUserInfo = data),
        shareReplay(1)
      );
    }
  }

  isAuthenticated(): Observable<any>{
    const url=`${environment.apiBaseUrl}user/isAuthenticated`;
    return this.httpClient.get(url);
  }

  isPlanOwner(planId: string): Observable<any> {
    const url=`${environment.apiBaseUrl}user/isPlanOwner/${planId}`;
    return this.httpClient.get(url);
  }
}
