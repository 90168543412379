import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PublicSecureService {
  private apiBaseUrl;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl ;
  }

  getAuthorizationUrl(lang='en'): Observable<any> {
    const url=`${this.apiBaseUrl}PublicSecure?language=${lang}`;
    return this.http.get(url)
  }

  getUserProfileUrl(): Observable<any> {
    const url=`${this.apiBaseUrl}PublicSecure/userprofile`;
    return this.http.get(url)
  }
}
